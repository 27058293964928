import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  Input,
  InfoItemVertical,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
} from 'components2';

import ItemCheclistApi from '../__AnalisaChecklistApi__';
import { InfoItemHorizontal } from 'components';
import { TableAnalisaChecklistContent } from './TableAnalisaChecklistContent';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const FormAnalisaChecklistContent = ({ action }) => {
  const { values, handleSubmit } = useFormikContext();
  const history = useHistory();

  // fetch dropdown jenisChecklist
  const {
    data: itemChecklistPerlengkapan,
    isFetching: loadingItemCHecklistPerlengkapan,
  } = useQuery(['itemChecklistPerlengkapan', 'dropdown'], () =>
    ItemCheclistApi.getDropdownItemChecklist({ jenis_checklist: 1 })
  );

  const { data: itemChecklistFungsi, isFetching: loadingItemCHecklistFungsi } =
    useQuery(['itemChecklistFungsi', 'dropdown'], () =>
      ItemCheclistApi.getDropdownItemChecklist({ jenis_checklist: 2 })
    );

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">Detail Data Item Aset</small>
        <ButtonBack
          size="sm"
          onClick={() => history.push('/asm/master/analisa-checklist')}
        />
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <InfoItemHorizontal
                label="Kode Aset"
                text={values?.kode_item_aset}
              />
              <InfoItemHorizontal
                label="Item Aset"
                text={values?.nama_item_aset}
              />
              <InfoItemHorizontal
                label="Grup Aset"
                text={values?.nama_grup_aset}
              />
              <InfoItemHorizontal
                label="Kategori Aset"
                text={values?.nama_kategori_aset}
              />
              <InfoItemHorizontal
                label="Jenis Aset"
                text={values?.nama_jenis_aset}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="mt-2">
        <small className="font-weight-bold">Checklist Perlengkapan</small>
      </div>
      <Card>
        <Card.Body>
          <TableAnalisaChecklistContent
            dropdown={itemChecklistPerlengkapan}
            dropdownLoading={loadingItemCHecklistPerlengkapan}
            dataTable={values.detail_perlengkapan}
            type="perlengkapan"
            action={action}
          />
        </Card.Body>
      </Card>
      <div className="mt-2">
        <small className="font-weight-bold">Checklist Fungsi</small>
      </div>
      <Card>
        <Card.Body>
          <TableAnalisaChecklistContent
            dropdown={itemChecklistFungsi}
            dropdownLoading={loadingItemCHecklistFungsi}
            dataTable={values.detail_fungsi}
            type="fungsi"
            action={action}
          />
        </Card.Body>
      </Card>
      {action !== 'detail' && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === 'update' ? 'success' : 'primary'}
            onClick={handleSubmit}
          />
        </div>
      )}
    </Fragment>
  );
};
