import Services from "services";

class MaintenanceRequestApi {
  async getList(params) {
    const fetch = await Services.get("/maintenance_request/page", {
      params,
    });
    return fetch.data;
  }

  async getListItemAset(params) {
    const fetch = await Services.get(
      "/analisa_item_checklist/item_aset_available",
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingleItemAset(params) {
    const fetch = await Services.get(
      "/analisa_item_checklist/single_item_aset_available",
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get("/maintenance_request/single", {
      params,
    });
    return fetch.data;
  }

  async getDropdownItemChecklist(params) {
    const fetch = await Services.get("/item_checklist", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_checklist,
      value: val.id_item_checklist,
      kode: val.kode_item_checklist,
    }));
  }

  async getDropdownJenisChecklist() {
    return [
      {
        value: "1",
        label: "Perlengkapan",
      },
      {
        value: "2",
        label: "Fungsi",
      },
    ];
  }

  async getDropdownGrupAset(params) {
    const fetch = await Services.get("/grupaset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_grup_aset,
      value: val.id_grup_aset,
    }));
  }

  async getDropdownKategoriAset(params) {
    const fetch = await Services.get("/kategoriaset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kategori_aset,
      value: val.id_kategori_aset,
    }));
  }

  async getDropdownJenisAset(params) {
    const fetch = await Services.get("/jenisaset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_jenis_aset,
      value: val.id_jenis_aset,
    }));
  }

  async getDropdownItemAset(params) {
    const fetch = await Services.get("/analisa_item_checklist/item_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item_aset,
      value: val.id_item_aset,
      kode: val.kode_item_aset,
    }));
  }

  generateNumber(params) {
    return Services.get("/maintenance_request/no_baru", { params });
  }

  create(data) {
    return Services.post("/maintenance_request", data);
  }

  update(data) {
    return Services.put("/maintenance_request", data);
  }
}

export default new MaintenanceRequestApi();
