const generateStatusApproval = (data) => {
    const newStatus = data?.status_progress?.toUpperCase()

    switch (newStatus) {
        case "ON PROGRESS":
            return {
                variant: "outline-warning",
                textColor: "text-warning",
                label: "ON PROGRESS",
                level: 2
            }
        case "CLOSED":
            return {
                variant: "outline-success",
                textColor: "text-success",
                label: "CLOSED",
                level: 1
            }
        default:
            return {
                variant: "outline-secondary",
                textColor: "text-secondary",
                label: "PENDING",
                level: 0
            }
    }
}

export default generateStatusApproval