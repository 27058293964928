import { ButtonBack, ButtonCreate } from '@bhawanadevteam/react-core';
import {
  DataStatus,
  DatePicker,
  InfoItemHorizontal,
  Input,
  InputQtyDecimal,
  SelectSearch,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  TextArea,
  Th,
  ThFixed,
  Tr,
} from 'components';
import { Formik, useFormikContext } from 'formik';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import MaintenanceRequestApi from '../__PermintaanWorkOrderApi__';
import { DateConvert, DecimalConvert } from 'utilities';
import PermintaanWorkOrderApi from '../__PermintaanWorkOrderApi__';

const ModalContentOverhead = ({ modalConfig }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  return (
    <>
      <DatePicker
        label="Tgl. Permintaan Work Order"
        selected={
          values?.tgl_permintaan_work_order
            ? new Date(values?.tgl_permintaan_work_order)
            : null
        }
        onChange={(val) =>
          setFieldValue(
            'tgl_permintaan_work_order',
            DateConvert(new Date(val)).default
          )
        }
        error={
          errors.tgl_permintaan_work_order &&
          touched.tgl_permintaan_work_order &&
          true
        }
        errorText={
          errors.tgl_permintaan_work_order &&
          touched.tgl_permintaan_work_order &&
          errors.tgl_permintaan_work_order
        }
      />

      <small className="font-weight-bold text-capitalize mt-2">
        List Data Komponen Overhead
      </small>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode Overhead</ThFixed>
            <Th>Item Overhead</Th>
            <ThFixed>Qty. Produksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {modalConfig?.data?.length > 0 ? (
            modalConfig?.data?.map((val, ind) => (
              <Tr key={ind}>
                <TdFixed>{ind + 1}</TdFixed>
                <TdFixed>{val.kode_item ?? '-'}</TdFixed>
                <Td>{val?.nama_item ?? '-'}</Td>
                <TdFixed className="text-right">
                  {DecimalConvert(parseFloat(val?.qty_work_order_overhead ?? 0))
                    .getWithComa +
                    ' ' +
                    val?.nama_satuan}
                </TdFixed>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="4">
                <DataStatus text="Data tidak ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

const ModalContent = ({ modalConfig, type, typeAlatMesinCheck }) => {
  const { values, setValues, setFieldValue, errors, touched } =
    useFormikContext();

  const {
    data: dropdownItemPermintaan,
    isFetching: loadingDropdownItemPermintaan,
  } = useQuery(['itemPermintaan', 'dropdown'], () =>
    PermintaanWorkOrderApi.getDropdownItem({ id_buaso: values?.id_buaso })
  );

  const { isFetching: loadingKode } = useQuery(
    ['permintaanWorkOrder', 'kode', values?.tgl_permintaan_work_order],
    () =>
      PermintaanWorkOrderApi.generateNumber({
        tanggal: values?.tgl_permintaan_work_order,
        id_buaso: values?.id_buaso,
      }).then((val) =>
        setValues({ ...values, no_permintaan_work_order: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_permintaan_work_order,
    }
  );

  return (
    <>
      <InfoItemHorizontal
        className="text-capitalize"
        label={`Kode ${type}`}
        text={
          typeAlatMesinCheck === 'alat_mesin'
            ? modalConfig?.data?.kode_item_aset
            : modalConfig?.data?.kode_item
        }
      />
      <InfoItemHorizontal
        className="text-capitalize"
        label={`Nama ${type}`}
        text={
          typeAlatMesinCheck === 'alat_mesin'
            ? modalConfig?.data?.nama_item_aset
            : modalConfig?.data?.nama_item
        }
      />
      <InfoItemHorizontal
        label="Satuan"
        text={modalConfig?.data?.nama_satuan ?? '-'}
      />
      <InfoItemHorizontal
        label="Qty. Work Order"
        text={
          DecimalConvert(
            parseFloat(
              modalConfig?.data?.[`qty_work_order_${typeAlatMesinCheck}`] ?? 0
            )
          ).getWithComa
        }
      />
      <InfoItemHorizontal
        label="Qty. Telah Diminta"
        text={
          DecimalConvert(parseFloat(modalConfig?.data?.qty_diminta ?? 0))
            .getWithComa
        }
      />
      <InfoItemHorizontal
        label="Qty. Telah Di Transfer"
        text={
          DecimalConvert(
            parseFloat(modalConfig?.data?.qty_telah_ditransfer ?? 0)
          ).getWithComa
        }
      />

      <hr />
      <Row>
        <Col>
          <DatePicker
            label="Tgl. Permintaan Work Order"
            selected={
              values?.tgl_permintaan_work_order
                ? new Date(values?.tgl_permintaan_work_order)
                : null
            }
            onChange={(val) =>
              setValues({
                ...values,
                tgl_permintaan_work_order: DateConvert(new Date(val)).default,
                id_item:
                  modalConfig?.data?.[
                    `id_item_${type === 'alat mesin' ? 'aset' : type}`
                  ],
              })
            }
            error={
              errors.tgl_permintaan_work_order &&
              touched.tgl_permintaan_work_order &&
              true
            }
            errorText={
              errors.tgl_permintaan_work_order &&
              touched.tgl_permintaan_work_order &&
              errors.tgl_permintaan_work_order
            }
          />
        </Col>
        <Col>
          <Input
            label="No. Permintaan Work Order"
            readOnly
            placeholder={
              loadingKode ? 'Memuat data...' : 'Pilih tanggal terlebih dahulu'
            }
            value={values?.no_permintaan_work_order}
          />
        </Col>
      </Row>
      {console.log(modalConfig?.data)}
      <SelectSearch
        label="Item Permintaan"
        option={dropdownItemPermintaan}
        isDisabled
        defaultValue={{
          value:
            modalConfig?.data?.[
              `id_item_${type === 'alat mesin' ? 'aset' : type}`
            ],

          label:
            modalConfig?.data?.[
              `nama_${type === 'alat mesin' ? 'item_aset' : 'item'}`
            ],
        }}
        loading={loadingDropdownItemPermintaan}
        name="id_item"
        placeholder="Pilih item permintaan"
        onChange={(val) =>
          setValues({
            ...values,
            id_item: val.value,
          })
        }
        error={errors.id_item && touched.id_item && true}
        errorText={errors.id_item && touched.id_item && errors.id_item}
      />
      <Row>
        <Col>
          <InputQtyDecimal
            label="Qty. Permintaan Work Order"
            name="qty_permintaan_work_order"
            placeholder="Masukan qty permintaan"
            value={values?.qty_permintaan_work_order}
            onChange={(val) => setFieldValue('qty_permintaan_work_order', val)}
            error={
              errors.qty_permintaan_work_order &&
              touched.qty_permintaan_work_order &&
              true
            }
            errorText={
              errors.qty_permintaan_work_order &&
              touched.qty_permintaan_work_order &&
              errors.qty_permintaan_work_order
            }
          />
        </Col>
        <Col>
          <Input
            label="Satuan"
            readOnly
            value={modalConfig?.data?.nama_satuan ?? '-'}
          />
        </Col>
      </Row>
    </>
  );
};

export const ModalFormBuaso = ({
  setModalConfig,
  modalConfig,
  type,
  typeAlatMesinCheck,
}) => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ show: false, data: {}, type: '' })}
    >
      <Modal.Header closeButton>Tambah Permintaan Work Order</Modal.Header>
      <Modal.Body>
        {type === 'overhead' ? (
          <ModalContentOverhead modalConfig={modalConfig} />
        ) : (
          <ModalContent
            modalConfig={modalConfig}
            type={type}
            typeAlatMesinCheck={typeAlatMesinCheck}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonBack
          onClick={() => setModalConfig({ show: false, data: {}, type: '' })}
        />
        <ButtonCreate
          text="Simpan"
          onClick={handleSubmit}
          loading={isSubmitting}
        />
      </Modal.Footer>
    </Modal>
  );
};
