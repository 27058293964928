import { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Alert, BackButton, DataStatus, InfoItemHorizontal } from 'components';
import { Formik } from 'formik';
import { DateConvert } from 'utilities';
import { RealisasiWorkOrderApi } from 'api';
import {
  InfoSectionDetailWorkOrder,
  TabAlatMesinOverhead,
  TabBahan,
  TableRealisasiPekerjaan,
  TabUpahSubkon,
} from './components';
import { realisasiWorkOrderProvider } from './context';
import { formInitialValues } from './helpers';

export const DetailRealisasiWorkOrder = realisasiWorkOrderProvider(
  ({ setNavbarTitle }) => {
    const title = 'Realisasi Work Order';
    const location = useLocation();
    const { id_realisasi_work_order } = useParams();
    const history = useHistory();
    const formikRef = useRef(null);
    const isReadOnly = true;

    const [data, setData] = useState({});
    const [page, setPage] = useState({
      loading: true,
      status: false,
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });

    const getData = () => {
      setPage({
        loading: true,
        status: false,
      });

      RealisasiWorkOrderApi.single({
        id_realisasi_work_order,
      })
        .then((res) => {
          const data = res?.data?.data ?? {};

          setData(data);
          setPage({
            loading: false,
            status: true,
          });
        })
        .catch(() => {
          setPage({
            loading: false,
            status: true,
          });
        });
    };

    useEffect(() => {
      setNavbarTitle(title);
      getData();

      // eslint-disable-next-line
    }, []);

    return (
      <>
        <div className="text-right">
          <BackButton
            onClick={() =>
              history.replace('/transaksi/realisasi-work-order', location.state)
            }
          />
        </div>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => {
            setAlertConfig({
              ...alertConfig,
              show: false,
            });
          }}
        />

        {page.loading || !page.status ? (
          <DataStatus
            loading={page.loading}
            text={
              page.loading
                ? 'Memuat data...'
                : 'Data gagal dimuat, tidak dapat menampilkan data'
            }
          />
        ) : (
          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={formInitialValues(data, isReadOnly)}
          >
            {({ values }) => {
              return (
                <>
                  <InfoSectionDetailWorkOrder data={data} />

                  <div
                    className="font-weight-bold "
                    style={{ fontSize: '14px' }}
                  >
                    Detail Realisasi Work Order
                  </div>

                  <Card className="mb-3">
                    <Card.Body>
                      <Row>
                        <Col>
                          <InfoItemHorizontal
                            label="Tgl. Realisasi Work Order"
                            text={
                              values.tgl_realisasi_work_order
                                ? DateConvert(
                                    new Date(values.tgl_realisasi_work_order)
                                  ).detail
                                : '-'
                            }
                          />

                          <InfoItemHorizontal
                            label="No. Realisasi Work Order"
                            text={values.no_realisasi_work_order ?? '-'}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <TableRealisasiPekerjaan readOnly />

                  <div
                    className="font-weight-bold"
                    style={{ fontSize: '14px' }}
                  >
                    Realisasi Biaya
                  </div>

                  <Card className="mb-3">
                    <Tab.Container defaultActiveKey="bahan">
                      <Card.Header>
                        <Nav variant="tabs" defaultActiveKey="bahan">
                          <NavTab eventKey="bahan" title="Bahan" />
                          <NavTab eventKey="upah" title="Upah" />
                          <NavTab eventKey="alat" title="Alat Mesin" />
                          <NavTab eventKey="subkon" title="Subkon" />
                          <NavTab eventKey="overhead" title="Overhead" />
                        </Nav>
                      </Card.Header>

                      {/* Tab Content */}
                      <Card.Body className="p-3">
                        <Tab.Content>
                          <TabPane
                            eventKey="bahan"
                            component={<TabBahan readOnly />}
                          />
                          <TabPane
                            eventKey="upah"
                            component={<TabUpahSubkon readOnly buaso="upah" />}
                          />
                          <TabPane
                            eventKey="alat"
                            component={
                              <TabAlatMesinOverhead
                                readOnly
                                buaso="alat_mesin"
                              />
                            }
                          />
                          <TabPane
                            eventKey="subkon"
                            component={
                              <TabUpahSubkon readOnly buaso="subkon" />
                            }
                          />
                          <TabPane
                            eventKey="overhead"
                            component={
                              <TabAlatMesinOverhead readOnly buaso="overhead" />
                            }
                          />
                        </Tab.Content>
                      </Card.Body>
                    </Tab.Container>
                  </Card>
                </>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
);

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
