import Services from "../../../services";

class LaporanDaftarAsetApi {
  getReport(params) {
    return Services.get("/laporan_daftar_aset", { params });
  }

  getDropdown(params) {
    // params : grup | kategori | jenis | item_aset
    return Services.get("/laporan_daftar_aset/dropdown", { params });
  }
}

export default new LaporanDaftarAsetApi();
