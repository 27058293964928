import React, { Fragment, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { Input, InfoItemVertical, Select, TextArea } from 'components2';

import ItemCheclistApi from '../__ItemChecklistApi__';

export const FormItemChecklistContent = ({ action }) => {
  const { values, errors, touched, setValues, handleChange, setFieldValue } =
    useFormikContext();

  // fetch dropdown jenisChecklist
  const { data: jenisChecklist, isLoading: loadingJenisChecklist } = useQuery(
    ['jenisChecklist', 'dropdown'],
    () => ItemCheclistApi.getDropdownJenisChecklist()
  );

  // fetch kode
  const { isLoading: loadingKode } = useQuery(
    ['kode', 'generateKode', values?.jenis_checklist],
    () =>
      ItemCheclistApi.generateNumber({
        jenis_checklist: values?.jenis_kode,
      }).then((val) => setFieldValue('kode_item', val?.data?.data)),
    {
      enabled: !!values.jenis_kode,
    }
  );

  if (action === 'DETAIL') {
    return (
      <Fragment>
        <Row>
          <Col>
            <InfoItemVertical
              label="Jenis Checklist"
              text={values?.nama_jenis_checklist}
            />
            <InfoItemVertical
              label="Kode Item Checklist"
              text={values?.kode_item}
            />
            <InfoItemVertical
              label="Nama Item Checklist"
              text={values?.nama_item_checklist}
            />
            <InfoItemVertical label="Keterangan" text={values?.keterangan} />
          </Col>
        </Row>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Select
        label="Jenis Checklist"
        placeholder="Pilih jenis checklist"
        name="jenis_checklist"
        options={jenisChecklist ?? []}
        loading={loadingJenisChecklist}
        onChange={async (val) =>
          setValues({
            ...values,
            jenis_checklist: val.value,
            jenis_kode: val.value,
            nama_jenis_checklist: val.label,
          })
        }
        defaultValue={
          values.jenis_checklist && {
            label: values.nama_jenis_checklist,
            value: values.jenis_checklist,
          }
        }
        error={errors.jenis_checklist && touched.jenis_checklist && true}
        errorText={touched.jenis_checklist && errors.jenis_checklist}
      />
      <Input
        label="Kode Item Checklist"
        placeholder={'Pilih jenis terlebih dahulu'}
        disabled
        value={values.kode_item}
        loading={loadingKode}
        loadingText="Memuat kode..."
      />
      <Input
        label="Nama Item Checklist"
        placeholder="Masukan nama item checklist"
        name="nama_item_checklist"
        value={values.nama_item_checklist}
        onChange={handleChange}
        error={Boolean(
          errors.nama_item_checklist && touched.nama_item_checklist
        )}
        errorText={
          Boolean(errors.nama_item_checklist) && errors.nama_item_checklist
        }
      />
      <TextArea
        label="Keterangan"
        placeholder="Masukkan keterangan"
        name="keterangan"
        rows="4"
        value={values.keterangan}
        onChange={handleChange}
        error={Boolean(errors.keterangan && touched.keterangan)}
        errorText={Boolean(errors.keterangan) && errors.keterangan}
      />
    </Fragment>
  );
};
