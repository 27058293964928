import { Dropdown, DropdownButton, Spinner } from 'react-bootstrap';

const ExportButtonDropdown = ({
  loading,
  disabled,
  onExportExcel,
  onExportPdf,
}) => {
  return (
    <DropdownButton
      title={
        loading ? (
          <Spinner
            animation="border"
            variant="white"
            size="sm"
            className="mr-2"
          />
        ) : (
          'Export '
        )
      }
      variant="warning text-white"
      disabled={loading || disabled}
      className="ml-2 btn-block"
    >
      <Dropdown.Item disabled={!onExportExcel} onClick={onExportExcel}>
        Excel (.xlsx)
      </Dropdown.Item>

      <Dropdown.Item disabled={!onExportPdf} onClick={onExportPdf}>
        PDF (.pdf)
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default ExportButtonDropdown;
