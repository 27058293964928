import { ChartLine } from '@bhawanadevteam/react-core';
import React from 'react';

export const AsetChartLine = ({
  monthOptions,
  filter,
  rupiah,
  loading,
  data,
  title,
}) => {
  return (
    <div className="p-3 bg-white border rounded shadow-sm mt-3">
      <div className="text-center">
        <b>
          {title} {filter.year}
        </b>
      </div>
      {loading ? (
        <div className="d-flex align-items-center justify-content-center my-5">
          Memuat data . . .
        </div>
      ) : (
        <>
          <ChartLine
            showLegend={false}
            data={{
              labels: monthOptions.map((val) => val.label),
              datasets: [
                {
                  data: monthOptions.map((val) => {
                    const month = parseInt(val.value);
                    return data?.data?.[month] ?? 0;
                  }),
                },
              ],
            }}
            options={{
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (val) => val.raw,
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (val) => val,
                  },
                },
              },
            }}
          />
        </>
      )}
    </div>
  );
};
