import React, { useState, useEffect } from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';
import { Alert, CRUDLayout } from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from 'components2';
import { ModalFilter, TableList } from './__PermintaanAsetComps__';
import PenerimaanAsetApi from './__PenerimaanAsetApi__';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';

export const PenerimaanAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const [filter, setFilter] = useState({
    q: '',
    tgl_penerimaan_aset_mulai: undefined,
    tgl_penerimaan_aset_selesai: undefined,
    tgl_purchase_order_aset_mulai: undefined,
    tgl_purchase_order_selesai: undefined,
    id_vendor: undefined,
    status_approval: undefined,
    active: false,
  });

  const [paginationConfig, setPaginationConfig] = useState({
    tanah: {
      page: 1,
      per_page: 10,
    },
    bangunan: {
      page: 1,
      per_page: 10,
    },
    kendaraan: {
      page: 1,
      per_page: 10,
    },
    alatMesin: {
      page: 1,
      per_page: 10,
    },
    inventaris: {
      page: 1,
      per_page: 10,
    },
    sistem: {
      page: 1,
      per_page: 10,
    },
    lainnya: {
      page: 1,
      per_page: 10,
    },
  });

  const [alertPerTabConfig, setAlertPerTabConfig] = useState({
    tanah: {
      show: false,
      text: '',
      variant: 'primary',
    },
    bangunan: {
      show: false,
      text: '',
      variant: 'primary',
    },
    kendaraan: {
      show: false,
      text: '',
      variant: 'primary',
    },
    alatMesin: {
      show: false,
      text: '',
      variant: 'primary',
    },
    inventaris: {
      show: false,
      text: '',
      variant: 'primary',
    },
    sistem: {
      show: false,
      text: '',
      variant: 'primary',
    },
    lainnya: {
      show: false,
      text: '',
      variant: 'primary',
    },
  });

  const [modalFilter, setModalFilter] = useState(false);

  const getPenerimaanAsetTanah = useQuery(
    ['penerimaanAsetTanah', 'list', filter, paginationConfig?.tanah],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.tanah,
        id_grup_aset: 1,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          tanah: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Tanah Gagal Dimuat !!!',
          },
        })
      )
  );

  const getPenerimaanAsetBangunan = useQuery(
    ['penerimaanAsetBangunan', 'list', filter, paginationConfig?.bangunan],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.bangunan,
        id_grup_aset: 2,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          bangunan: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Bangunan Gagal Dimuat !!!',
          },
        })
      )
  );

  const getPenerimaanAsetKendaraan = useQuery(
    ['penerimaanAsetKendaraan', 'list', filter, paginationConfig?.kendaraan],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.kendaraan,
        id_grup_aset: 3,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          kendaraan: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Kendaraan Gagal Dimuat !!!',
          },
        })
      )
  );

  const getPenerimaanAsetAlatMesin = useQuery(
    ['penerimaanAsetAlatMesin', 'list', filter, paginationConfig?.alatMesin],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.alatMesin,
        id_grup_aset: 4,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          alatMesin: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Alat & Mesin Gagal Dimuat !!!',
          },
        })
      )
  );

  const getPenerimaanAsetInventaris = useQuery(
    ['penerimaanAsetInventaris', 'list', filter, paginationConfig?.inventaris],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.inventaris,
        id_grup_aset: 5,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          inventaris: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Inventaris Gagal Dimuat !!!',
          },
        })
      )
  );

  const getPenerimaanAsetSistem = useQuery(
    ['penerimaanAsetSistem', 'list', filter, paginationConfig?.sistem],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.sistem,
        id_grup_aset: 6,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          sistem: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Sistem-Sistem Gagal Dimuat !!!',
          },
        })
      )
  );

  const getPenerimaanAsetLainnya = useQuery(
    ['penerimaanAsetLainnya', 'list', filter, paginationConfig?.lainnya],
    () =>
      PenerimaanAsetApi.getList({
        ...filter,
        ...paginationConfig?.lainnya,
        id_grup_aset: 7,
      }).catch(() =>
        setAlertPerTabConfig({
          ...alertPerTabConfig,
          lainnya: {
            show: true,
            variant: 'danger',
            text: 'Data Aset Lainnya Gagal Dimuat !!!',
          },
        })
      )
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    tgl_penerimaan_aset_mulai: filter?.tgl_penerimaan_aset_mulai,
    tgl_penerimaan_aset_selesai: filter?.tgl_penerimaan_aset_selesai,
    tgl_purchase_order_aset_mulai: filter?.tgl_purchase_order_aset_mulai,
    tgl_purchase_order_selesai: filter?.tgl_purchase_order_selesai,
    id_vendor: filter?.id_vendor,
    status_approval: filter?.status_approval,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => {
    setNavbarTitle('Penerimaan Aset');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate
            size="md"
            icon
            onClick={() => history.push('penerimaan-aset/purchase-order')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        showCloseButton
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      <div className="mt-2">
        <small className="font-weight-bold">List Data Penerimaan Aset</small>
      </div>
      <Card className="p-0 m-0">
        <Tab.Container defaultActiveKey="tanah">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tanah">Tanah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="bangunan">Bangunan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="kendaraan">Kendaraan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alatMesin">Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="inventaris">Inventaris</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sistem">Sistem-Sistem</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="lainnya">Lainnya</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="tanah" className="p-2">
              <TableList
                query={getPenerimaanAsetTanah}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="tanah"
                refetch={getPenerimaanAsetTanah.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="bangunan" className="p-2">
              <TableList
                query={getPenerimaanAsetBangunan}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="bangunan"
                refetch={getPenerimaanAsetBangunan.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="kendaraan" className="p-2">
              <TableList
                query={getPenerimaanAsetKendaraan}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="kendaraan"
                refetch={getPenerimaanAsetKendaraan.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="alatMesin" className="p-2">
              <TableList
                query={getPenerimaanAsetAlatMesin}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="alatMesin"
                refetch={getPenerimaanAsetAlatMesin.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="inventaris" className="p-2">
              <TableList
                query={getPenerimaanAsetInventaris}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="inventaris"
                refetch={getPenerimaanAsetInventaris.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="sistem" className="p-2">
              <TableList
                query={getPenerimaanAsetSistem}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="sistem"
                refetch={getPenerimaanAsetSistem.refetch}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="lainnya" className="p-2">
              <TableList
                query={getPenerimaanAsetLainnya}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
                alertConfig={alertPerTabConfig}
                setAlertConfig={setAlertPerTabConfig}
                type="lainnya"
                refetch={getPenerimaanAsetLainnya.refetch}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
