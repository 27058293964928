import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormPermintaanWorkOrderContent } from './__PermintaanWorkOrderComps__';
import { useMutation, useQuery } from 'react-query';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import PermintaanWorkOrderApi from './__PermintaanWorkOrderApi__';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from './__MaintenanceRequestUtils__';
import { Alert, DataStatus } from 'components';

export const PermintaanWorkOrderCreate = ({ setNavbarTitle }) => {
  const [defaultTab, setDefaultTab] = useState('bahan');
  const history = useHistory();
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const {
    data: detailPermintaanWorkOrder,
    isFetching: loadingDetailPermintaanWorkOrder,
    refetch: refetch,
  } = useQuery(
    ['permintaanWorkOder', 'workOrderSingle', id],
    () =>
      PermintaanWorkOrderApi.getSingleWorkOrder({
        id_work_order: id,
      }),
    { enabled: !!id }
  );

  const createMaintenanceRequest = useMutation((data) =>
    PermintaanWorkOrderApi.selesai(data)
  );

  const handleRefetch = () => {
    // manually refetch
    refetch();
  };
  const formSubmitHandler = (value) => {
    createMaintenanceRequest
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push('/transaksi/permintaan-work-order', {
          alert: {
            variant: 'primary',
            show: true,
            text: 'Data berhasil disimpan!!!',
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal disimpan!!!',
        })
      );
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Work Order');
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailPermintaanWorkOrder ? (
        <DataStatus loading text="Memuat Data ..." />
      ) : (
        <Formik
          initialValues={formInitialValues(
            detailPermintaanWorkOrder?.data ?? {}
          )}
          onSubmit={formSubmitHandler}
        >
          <FormPermintaanWorkOrderContent
            action="create"
            defaultTab={defaultTab}
            setDefaultTab={setDefaultTab}
            setAlertConfig={setAlertConfig}
            alertConfig={alertConfig}
            refetch={handleRefetch}
          />
        </Formik>
      )}
    </>
  );
};
