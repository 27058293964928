export const MODAL_TITLE = {
  CREATE: 'Tambah',
  UPDATE: 'Ubah',
  DETAIL: 'Detail',
};

export const STATUS_APPROVAL = {
  PEN: 'PENDING',
  VER: 'VERIFIED',
  APP: 'APPROVED',
  REV: 'REVISE',
  REJ: 'REJECT',
};

export const KODE_MODUL = [
  'PRY',
  'ASM',
  'TNK',
  'SIA',
  'ANG',
  'CRM',
  'PRO',
  'PRD',
  'SLS',
  'COC',
  'INV',
  'MKT',
  'HRDA',
].map((kode) => ({ value: kode, label: kode }));
