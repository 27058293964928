import Services from 'services';

class DashboardApi {
  async getItemAset(params) {
    const fetch = await Services.get('/dashboard/item_aset', {
      params,
    });
    return fetch.data;
  }

  async getNilaiPerolehan(params) {
    const fetch = await Services.get('/dashboard/nilai_perolehan', {
      params,
    });
    return fetch.data;
  }

  async getKondisiAset(params) {
    const fetch = await Services.get('/dashboard/kondisi_aset', {
      params,
    });
    return fetch.data;
  }

  async getRekapitulasiPenyusutan(params) {
    const fetch = await Services.get('/dashboard/rekapitulasi_penyusutan', {
      params,
    });
    return fetch.data;
  }

  async getPembelianAset(params) {
    const fetch = await Services.get('/dashboard/pembelian_aset', {
      params,
    });
    return fetch.data;
  }

  async getProduksiAset(params) {
    const fetch = await Services.get('/dashboard/produksi_aset', {
      params,
    });
    return fetch.data;
  }

  async getTotalKendaraan(params) {
    const fetch = await Services.get('/dashboard/kendaraan', {
      params,
    });
    return fetch.data;
  }
}

export default new DashboardApi();
