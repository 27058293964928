// IMPORT DEPEDENCY
import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "../components";

// IMPORT PAGE DASHBOARD
import Dashboard from "../pages/Dashboard";

// IMPORT PAGE 404
import NotFound from "../pages/404";

import { AuthContext, NotifContext } from "../utilities";

import Login from "../pages/Login";
import Config from "../config"
import { AuthApi } from "api";

const MyRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataNotif, setDataNotif] = useState({});

  const { state } = useContext(AuthContext);
  const { isAuthenticated, role } = state;
  const {ROUTES} = Config

  // Fetch notifikasi
  const checkNotifHandler = () => {
    setIsLoading(true);

    AuthApi.notif()
      .then((res) => {
        const notif = res.data.data;
        setDataNotif(notif);
      })
      .catch(() => { window.alert("Notifikasi gagal diperoleh!") })
      .finally(() => { setIsLoading(false) });
  };
  useEffect(() => {
    isAuthenticated && checkNotifHandler();
    !isAuthenticated && setIsLoading(false);
  }, [isAuthenticated, role])

  // useEffect(() => {
  // }, [state]);

  const AuthRoutes = ({ setNavbarTitle }) => (
    <Switch>
      {/* DASHBOARD */}
      <Route exact path="/">
        <Dashboard setNavbarTitle={setNavbarTitle} />
      </Route>

      {/* ROUTES */}
      {ROUTES.map((val, index) => 
        val.hak && 
        val.hak.some(hak => role.includes(hak)) && (
        <Route
          key={index}
          exact={val.exact}
          path={val.path}
        >
          <val.page setNavbarTitle={setNavbarTitle} />
        </Route>
      ))}

      <Route path="/login">
        <Redirect to="/" />
      </Route>

      <Route path="*">
        <NotFound setNavbarTitle={setNavbarTitle} />
      </Route>
    </Switch>
  );

  const UnAuthRoutes = () => (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );

  const Routes = () => {
    if (!isAuthenticated) {
      return <UnAuthRoutes />;
    }

    return (
      <Layout>
        <AuthRoutes />
      </Layout>
    );
  };

  return (
    <NotifContext.Provider value={dataNotif}>
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
    </NotifContext.Provider>
  );
};

export default MyRoutes;
