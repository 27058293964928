export const AlertCloseWorkOrder = () => {
  return (
    <div
      className="p-2 mt-4 rounded"
      style={{
        backgroundColor: '#ffcccc',
        color: '#cc0000',
      }}
    >
      <p>
        Data Work Order ini akan berubah statusnya dari
        <b> ON PROGRESS </b>
        menjadi
        <b> CLOSED </b>
        dan tidak dapat dikembalikan. Data Work Order dengan status
        <b> CLOSED </b>
        tidak dapat digunakan sebagai acuan:
        <ul className="pl-3 mt-1">
          <li>Permintaan Work Order</li>
          <li>Transfer Work Order</li>
          <li>Realisasi Work Order</li>
          <li>Posting Realisasi Work Order</li>
        </ul>
      </p>
    </div>
  );
};
