import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import _ from "lodash";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  SelectSearch,
} from "components";
import { RupiahConvert } from "utilities";
import { LaporanPenyusutanAsetApi } from "api";

const LaporanPenyusutanAset = ({ setNavbarTitle }) => {
  const title = "Laporan Penyusutan Aset";
  const [isFetchingReport, setIsFetchingReport] = useState(false); // Loading getReportData
  const [data, setData] = useState([]); // State to store result report data
  const [dropdown, setDropdown] = useState({
    // State data dropdown
    grup_aset: [],
    kategori_aset: [],
    jenis_aset: [],
    item_aset: [],
  });
  const [loadingDropdown, setLoadingDropdown] = useState({
    // State loading data dropdown
    grup_aset: false,
    kategori_aset: false,
    jenis_aset: false,
    item_aset: false,
  });
  const [searchFilter, setSearchFilter] = useState({
    // State search filter
    id_grup_aset: "",
    id_kategori_aset: "",
    id_jenis_aset: "",
    id_item_aset: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  // FETCH DATA DROPDOWN GRUP
  const getGrupAset = () => {
    // Dropdown Loading
    setLoadingDropdown({
      grup_aset: true,
      kategori_aset: true,
      jenis_aset: true,
      item_aset: true,
    });

    const mappingDropdwonGrupAset = (data) => {
      // mapping data api
      const dataGrupAset = data.map(
        (val) =>
          new Object({
            value: val.id_grup_aset,
            label: val.nama_grup_aset,
            kode_grup_aset: val.kode_grup_aset,
          })
      );

      // tambahkan option untuk 'Semua Grup Aset'
      data.length > 0 &&
        dataGrupAset.unshift({ value: "0", label: "Semua Grup Aset" });
      return dataGrupAset;
    };

    LaporanPenyusutanAsetApi.getDropdown({ tipe: "grup" })
      .then((res) => {
        const mapDropdownGrup = mappingDropdwonGrupAset(res?.data?.data ?? []);
        setDropdown({
          ...dropdown,
          grup_aset: mapDropdownGrup,
        });
      })
      .catch(() => {
        setDropdown({
          ...dropdown,
          grup_aset: [],
        });
      })
      .finally(() =>
        setLoadingDropdown({
          grup_aset: false,
          kategori_aset: false,
          jenis_aset: false,
          item_aset: false,
        })
      );
  };

  const getKategoriAset = (id_grup_aset) => {
    // Dropdown Loading
    setLoadingDropdown({
      ...loadingDropdown,
      kategori_aset: true,
      jenis_aset: true,
      item_aset: true,
    });

    const mappingDropdownKategoriAset = (data) => {
      // mapping data api
      const dataKategoriAset = data.map(
        (val) =>
          new Object({
            value: val.id_kategori_aset,
            label: val.nama_kategori_aset,
            id_grup_aset: val.id_grup_aset,
            kode_kategori_aset: val.kode_kategori_aset,
          })
      );

      // tambahkan option untuk 'Semua Kategori Aset'
      data.length > 0 &&
        dataKategoriAset.unshift({
          value: "0",
          label: "Semua Kategori Aset",
        });
      return dataKategoriAset;
    };

    const query = { tipe: "kategori" };
    if (id_grup_aset !== "0") query.id_grup_aset = id_grup_aset;

    LaporanPenyusutanAsetApi.getDropdown(query)
      .then((res) => {
        const mapDropdownKategori = mappingDropdownKategoriAset(
          res?.data?.data ?? []
        );
        setDropdown({
          ...dropdown,
          kategori_aset: mapDropdownKategori,
        });
      })
      .catch(() => {
        setDropdown({
          ...dropdown,
          kategori_aset: [],
        });
      })
      .finally(() =>
        setLoadingDropdown({
          kategori_aset: false,
          jenis_aset: false,
          item_aset: false,
        })
      );
  };

  const getJenisAset = (id_kategori_aset) => {
    // Dropdown Loading
    setLoadingDropdown({
      ...loadingDropdown,
      jenis_aset: true,
      item_aset: true,
    });

    const mappingDropdownJenisAset = (data) => {
      // mapping data api
      const dataJenisAset = data.map(
        (val) =>
          new Object({
            value: val.id_jenis_aset,
            label: val.nama_jenis_aset,
            id_grup_aset: val.id_grup_aset,
            kode_kategori_aset: val.kode_kategori_aset,
          })
      );

      // tambahkan option untuk 'Semua Jenis Aset'
      data.length > 0 &&
        dataJenisAset.unshift({
          value: "0",
          label: "Semua Jenis Aset",
        });
      return dataJenisAset;
    };

    const query = { tipe: "jenis" };
    if (id_kategori_aset !== "0") query.id_kategori_aset = id_kategori_aset;
    if (searchFilter.id_grup_aset !== "0")
      query.id_grup_aset = searchFilter.id_grup_aset;

    LaporanPenyusutanAsetApi.getDropdown(query)
      .then((res) => {
        const mapDropdownJenis = mappingDropdownJenisAset(
          res?.data?.data ?? []
        );
        setDropdown({
          ...dropdown,
          jenis_aset: mapDropdownJenis,
        });
      })
      .catch(() => {
        setDropdown({
          ...dropdown,
          jenis_aset: [],
        });
      })
      .finally(() =>
        setLoadingDropdown({
          ...loadingDropdown,
          jenis_aset: false,
          item_aset: false,
        })
      );
  };

  const getItemAset = (id_jenis_aset) => {
    setLoadingDropdown({
      ...loadingDropdown,
      item_aset: true,
    });

    const mappingDropdownItemAset = (data) => {
      // mapping data api
      const dataItemAset = data.map(
        (val) =>
          new Object({
            value: val.id_item_aset,
            label: val.nama_item_aset,
            kode_item_aset: val.kode_item_aset,
          })
      );

      // tambahkan option untuk 'Semua Jenis Item'
      data.length > 0 &&
        dataItemAset.unshift({
          value: "0",
          label: "Semua Jenis Item",
        });
      return dataItemAset;
    };

    const query = { tipe: "item_aset" };
    if (id_jenis_aset !== "0") query.id_jenis_aset = id_jenis_aset;
    if (searchFilter.id_grup_aset !== "0")
      query.id_grup_aset = searchFilter.id_grup_aset;
    if (searchFilter.id_kategori_aset !== "0")
      query.id_kategori_aset = searchFilter.id_kategori_aset;

    LaporanPenyusutanAsetApi.getDropdown(query)
      .then((res) => {
        const mapDropdownItem = mappingDropdownItemAset(res?.data?.data ?? []);
        setDropdown({
          ...dropdown,
          item_aset: mapDropdownItem,
        });
      })
      .catch(() => {
        setDropdown({
          ...dropdown,
          item_aset: [],
        });
      })
      .finally(() =>
        setLoadingDropdown({ ...loadingDropdown, item_aset: false })
      );
  };

  // FETCH DATA REPORT SESUAI FILTER
  const getReportData = () => {
    setIsFetchingReport(true);
    const query = {};

    if (searchFilter.id_grup_aset !== "0")
      query.id_grup_aset = searchFilter.id_grup_aset;
    if (searchFilter.id_kategori_aset !== "0")
      query.id_kategori_aset = searchFilter.id_kategori_aset;
    if (searchFilter.id_jenis_aset !== "0")
      query.id_jenis_aset = searchFilter.id_jenis_aset;
    if (searchFilter.id_item_aset !== "0")
      query.id_item_aset = searchFilter.id_item_aset;

    LaporanPenyusutanAsetApi.getReport(query)
      .then(({ data }) => setData(data?.data ?? []))
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsFetchingReport(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getGrupAset();

    return () => {
      // CLEANUP STATE
      setDropdown({});
      setSearchFilter({});
      setData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <ThFixed width="12px">No</ThFixed>
            <Th width="12px">Kode Aset</Th>
            <Th>Item Aset</Th>
            <Th>Grup Aset</Th>
            <Th>Kategori Aset</Th>
            <Th>Jenis Aset</Th>
            <Th>Penyusutan Garis Lurus Per Bulan</Th>
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              <TdFixed>{index + 1}</TdFixed>
              <Td>{val?.kode_item_aset ?? "-"}</Td>
              <Td>{val?.nama_item_aset ?? "-"}</Td>
              <Td>{val?.nama_grup_aset ?? "-"}</Td>
              <Td>{val?.nama_kategori_aset ?? "-"}</Td>
              <Td>{val?.nama_jenis_aset ?? "-"}</Td>
              <Td textRight>
                {"Rp" +
                  RupiahConvert(
                    String(
                      parseFloat(val?.penyusutan_garis_lurus_per_bulan || "0")
                    )
                  ).getWithComa}
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return (
        <div style={{ minHeight: "45vh" }}>
          <DataStatus text="Tidak ada data" />
        </div>
      );
    }

    return <DataTable />;
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <Card className="mb-3">
        <Card.Header>
          <strong>Pencarian Data</strong>
        </Card.Header>
        <Card.Body>
          {loadingDropdown.grup_aset ? (
            <DataStatus
              text="Memuat Data ..."
              loading={loadingDropdown.grup_aset}
            />
          ) : (
            <Row>
              <Col lg="2">
                <SelectSearch
                  label="Grup Aset"
                  placeholder="Pilih Grup Aset"
                  option={dropdown.grup_aset}
                  onChange={(val) => {
                    setSearchFilter({
                      id_grup_aset: val.value,
                      id_kategori_aset: "",
                      id_jenis_aset: "",
                      id_item_aset: "",
                    });
                    getKategoriAset(val.value);
                  }}
                />
              </Col>

              <Col lg="2">
                <SelectSearch
                  label="Kategori Aset"
                  placeholder="Pilih Kategori Aset"
                  option={dropdown.kategori_aset}
                  isDisabled={searchFilter.id_grup_aset === ""}
                  onChange={(val) => {
                    setSearchFilter({
                      ...searchFilter,
                      id_kategori_aset: val.value,
                      id_jenis_aset: "",
                      id_item_aset: "",
                    });
                    getJenisAset(val.value);
                  }}
                  loading={loadingDropdown.kategori_aset}
                />
              </Col>

              <Col lg="2">
                <SelectSearch
                  label="Jenis Aset"
                  placeholder="Pilih Jenis Aset"
                  option={dropdown.jenis_aset}
                  isDisabled={searchFilter.id_kategori_aset === ""}
                  onChange={(val) => {
                    setSearchFilter({
                      ...searchFilter,
                      id_jenis_aset: val.value,
                      id_item_aset: "",
                    });
                    getItemAset(val.value);
                  }}
                  loading={loadingDropdown.jenis_aset}
                />
              </Col>

              <Col lg="5">
                <SelectSearch
                  label="Item Aset"
                  placeholder="Pilih Item Aset"
                  option={dropdown.item_aset}
                  isDisabled={searchFilter.id_jenis_aset === ""}
                  onChange={(val) =>
                    setSearchFilter({
                      ...searchFilter,
                      id_item_aset: val.value,
                    })
                  }
                  loading={loadingDropdown.item_aset}
                />
              </Col>

              <Col lg="1" className="d-flex align-items-center pt-3">
                <ActionButton
                  text="Cari"
                  size="sm"
                  onClick={() => {
                    if (
                      searchFilter.id_grup_aset === "" ||
                      searchFilter.id_item_aset === "" ||
                      searchFilter.id_jenis_aset === "" ||
                      searchFilter.id_kategori_aset === ""
                    ) {
                      setAlertConfig({
                        show: true,
                        text: "Kolom Pencarian Data harus diisi",
                        variant: "danger",
                      });
                    } else {
                      getReportData();
                    }
                  }}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isFetchingReport ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <div style={{ minHeight: "45vh" }}>
          <PageContent />
        </div>
      )}
    </CRUDLayout>
  );
};

export default LaporanPenyusutanAset;
