import {
  CRUDLayout,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from "components";
import { useState } from "react";
import { DateConvert, DecimalConvert, PageNumber } from "utilities";
import { TransferModal } from "../modals";

const TableTransfer = ({ isPre, data, dataFilter, title }) => {
  // States
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const toggleModal = () => setModal(!modal);

  const HeaderAksi = () => <ThFixed>Aksi</ThFixed>;
  const BodyAksi = ({ val }) => (
    <TdFixed>
      <ReadButton
        size="sm"
        onClick={() => {
          setDataModal(val);
          toggleModal();
        }}
      />
    </TdFixed>
  );

  return (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            {isPre && <HeaderAksi />}
            <ThFixed>Informasi Transfer Alat Mesin Produksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <Th>Item Alat & Mesin</Th>
            <ThFixed>Qty. Transfer</ThFixed>
            <Th>Diserahkan Oleh</Th>
            {isPre && <Th>Keterangan Transfer</Th>}
            {!isPre && <HeaderAksi />}
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              {isPre ? (
                <TdFixed>
                  {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                </TdFixed>
              ) : (
                <TdFixed>{index + 1}</TdFixed>
              )}
              {isPre && <BodyAksi val={val} />}
              <Td>
                <div>
                  {val.tgl_transfer_produksi_alat_mesin
                    ? DateConvert(
                        new Date(val.tgl_transfer_produksi_alat_mesin)
                      ).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_transfer_produksi_alat_mesin ?? "-"}</div>
              </Td>
              <Td>
                <div>
                  {val.tgl_permintaan_produksi_alat_mesin
                    ? DateConvert(
                        new Date(val.tgl_permintaan_produksi_alat_mesin)
                      ).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_permintaan_produksi_alat_mesin ?? "-"}</div>
              </Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td className="text-right">{`${
                DecimalConvert(val.qty_transfer ?? 0).getWithComa
              } ${val.kode_satuan ?? ""}`}</Td>
              <Td>{val.nama_karyawan ?? "-"}</Td>
              {isPre && <Td>{val.keterangan_transfer ?? "-"}</Td>}
              {!isPre && <BodyAksi val={val} />}
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>

      {modal && (
        <TransferModal toggle={toggleModal} data={dataModal} title={title} />
      )}
    </>
  );
};

export default TableTransfer;
