import { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { IoAddOutline, IoCheckmarkOutline } from 'react-icons/io5';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  Table,
  TBody,
  Td,
  TdFixed,
  TextArea,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { WorkOrderApi } from 'api';
import { InfoMaintenanceRequest, ModalCreateWorkOrder } from './components';
import { WorkOrderContext, workOrderProvider } from './context';

export const TambahWorkOrder = workOrderProvider(({ setNavbarTitle }) => {
  const title = 'Work Order';
  const location = useLocation();
  const { id_maintenance_request_master } = useParams();
  const history = useHistory();
  const { modalWorkOrder, setModalWorkOrder } = useContext(WorkOrderContext);

  const [infoMaintenance, setInfoMaintenance] = useState({});
  const [data, setData] = useState({});
  const [page, setPage] = useState({
    loading: true,
    status: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getData = () => {
    setPage({
      loading: true,
      status: false,
    });

    WorkOrderApi.getSingleMaintenance({ id_maintenance_request_master })
      .then((res) => {
        const {
          tgl_maintenance_request_master,
          no_maintenance_request_master,
          catatan,
          ...data
        } = res.data.data;

        setData(data);
        setInfoMaintenance({
          tgl_maintenance_request_master,
          no_maintenance_request_master,
          catatan,
        });
        setPage({
          loading: false,
          status: true,
        });
      })
      .catch(() => {
        setPage({
          loading: false,
          status: false,
        });
      });
  };

  const onSuccessCreate = () => {
    setAlertConfig({
      show: true,
      text: 'Tambah data berhasil',
      variant: 'primary',
    });
    getData();
  };

  useEffect(() => {
    setNavbarTitle(title);
    getData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="text-right">
        <BackButton
          onClick={() =>
            history.replace(
              '/transaksi/work-order/list-maintenance-request/',
              location.state,
            )
          }
        />
      </div>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {page.loading || !page.status ? (
        <DataStatus
          loading={page.loading}
          text={
            page.loading
              ? 'Memuat data...'
              : 'Data gagal dimuat, tidak dapat menampilkan data'
          }
        />
      ) : (
        <>
          <div className="font-weight-bold " style={{ fontSize: '14px' }}>
            Detail Data Maintenance Request
          </div>

          <Card className="mb-3">
            <Card.Body>
              <InfoMaintenanceRequest data={infoMaintenance} />
            </Card.Body>
          </Card>

          <div className="font-weight-bold" style={{ fontSize: '14px' }}>
            List Item Aset Yang Memerlukan Pemeliharaan
          </div>

          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Item Aset</ThFixed>
                <Th>Item Aset</Th>
                <Th>Keterangan</Th>
                <ThFixed>Status Order</ThFixed>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>

            <TBody>
              {data?.detail?.length ? (
                data.detail.map((val, index) => {
                  const available = val.status_order === false;

                  return (
                    <Tr key={val.id_maintenance_request_detail}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>
                        <div>{val.kode_item_aset}</div>
                      </Td>
                      <Td>{val.nama_item_aset ?? '-'}</Td>
                      <Td>{val.keterangan ?? '-'}</Td>
                      <Td className="text-uppercase text-nowrap">
                        <span
                          className={available ? 'text-danger' : 'text-success'}
                        >
                          {available ? 'Belum' : 'Sudah'} dibuat work order
                        </span>
                      </Td>
                      <TdFixed>
                        <ActionButton
                          text={
                            available ? (
                              <IoAddOutline size={12} />
                            ) : (
                              <IoCheckmarkOutline size={12} />
                            )
                          }
                          variant={available ? 'primary' : 'success'}
                          className="my-1"
                          size="sm"
                          disable={!available}
                          onClick={() => {
                            if (available) {
                              setModalWorkOrder({
                                show: true,
                                isCreate: true,
                                data: val,
                              });
                            }
                          }}
                        />
                      </TdFixed>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td colSpan={8}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>

          <TextArea
            disabled
            label="Catatan Maintenance Request"
            value={infoMaintenance.catatan}
            rows={4}
          />

          <ModalCreateWorkOrder
            show={modalWorkOrder.show}
            data={modalWorkOrder.data}
            infoMaintenance={infoMaintenance}
            onClose={() =>
              setModalWorkOrder({ show: false, isCreate: false, data: null })
            }
            onSuccessCreate={onSuccessCreate}
          />
        </>
      )}
    </>
  );
});
