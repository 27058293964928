import { useContext, useRef } from 'react';
import {
  ActionButton,
  DatePicker,
  Input,
  SelectSearch,
  TextArea,
} from 'components';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { InfoMaintenanceRequest } from './InfoMaintenanceRequest';
import { TableRincianPekerjaan } from './TableRincianPekerjaan';
import { TabRincianBiaya } from './TabRincianBiaya';
import { TableSummaryBiaya } from './TableSummaryBiaya';
import { WorkOrderApi } from 'api';
import { WorkOrderContext } from '../context';
import { DateConvert } from 'utilities';

export const ModalCreateWorkOrder = ({
  show,
  data,
  infoMaintenance,
  onClose,
  onSuccessCreate,
}) => {
  const formikRef = useRef();
  const { dropdownKaryawan, dropdownVendor } = useContext(WorkOrderContext);
  const formInitialValues = (data) => ({
    ...data,
    tgl_work_order: '',
    no_work_order: '',
    is_mekanik_eksternal: true,
    id_petugas_maintenance: null,
    nama_petugas_eksternal: null,
    id_vendor: null,
    rincian_pekerjaan: [],
    bahan: [],
    upah: [],
    alat_mesin: [],
    subkon: [],
    overhead: [],
  });

  const validationSchema = yup.object().shape({
    tgl_work_order: yup.date().required('Tgl. Work Order diperlukan'),
    is_mekanik_eksternal: yup.boolean().required(),
    id_petugas_maintenance: yup.string().when('is_mekanik_eksternal', {
      is: false,
      then: yup.string().required('Petugas maintenance diperlukan'),
      otherwise: yup.string().nullable(),
    }),
    nama_petugas_eksternal: yup.string().when('is_mekanik_eksternal', {
      is: true,
      then: yup.string().required('Nama petugas eksternal diperlukan'),
      otherwise: yup.string().nullable(),
    }),
    id_vendor: yup.string().when('is_mekanik_eksternal', {
      is: true,
      then: yup.string().required('Vendor diperlukan'),
      otherwise: yup.string().nullable(),
    }),
  });

  const getNomor = (tanggal) => {
    const { setFieldValue } = formikRef.current;
    setFieldValue('tgl_work_order', tanggal);

    WorkOrderApi.getNomor({ tanggal })
      .then((res) => {
        setFieldValue('no_work_order', res.data.data);
      })
      .catch(() => {
        alert('Gagal memuat nomor baru!');
      });
  };

  const onSubmit = (values, { setSubmitting }) => {
    const mapSubmit = {
      ...values,
      tgl_work_order: DateConvert(values.tgl_work_order).default,
      id_petugas_maintenance: values.is_mekanik_eksternal
        ? null
        : values.id_petugas_maintenance,
      nama_petugas_eksternal: values.is_mekanik_eksternal
        ? values.nama_petugas_eksternal
        : null,
      id_vendor: values.is_mekanik_eksternal ? values.id_vendor : null,
      alat_mesin: values.alat_mesin?.map((e) => ({
        qty_work_order_alat_mesin: e.qty_work_order_alat_mesin,
        id_item_aset: e.id_item_alat_mesin,
      })),
    };

    WorkOrderApi.save(mapSubmit)
      .then(() => {
        onSuccessCreate();
        onClose();
      })
      .catch(() => {
        alert('Gagal menyimpan data!');
        setSubmitting(false);
      });
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={formInitialValues(data)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }) => (
        <Modal scrollable show={show} size="lg" onHide={onClose}>
          <Modal.Header closeButton>Tambah Work Order</Modal.Header>
          <Modal.Body>
            <InfoMaintenanceRequest data={infoMaintenance} />

            <hr />

            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Work Order"
                  selected={
                    values?.tgl_work_order
                      ? new Date(values.tgl_work_order)
                      : null
                  }
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => getNomor(date)}
                  error={errors?.tgl_work_order && touched?.tgl_work_order}
                  errorText={errors?.tgl_work_order}
                />
              </Col>

              <Col>
                <Input
                  disabled
                  label="No. Work Order"
                  value={values.no_work_order}
                />
              </Col>
            </Row>

            <Input disabled label="Item Aset" value={values.nama_item_aset} />

            <div className="mt-3">
              <label className="d-flex justify-content-start align-items-center">
                <Field type="checkbox" name="is_mekanik_eksternal" />
                <small className="pl-1">Mekanik Eksternal</small>
              </label>
            </div>

            {values.is_mekanik_eksternal ? (
              <>
                <Input
                  label="Nama Petugas Maintenance"
                  value={values.nama_petugas_eksternal}
                  onChange={(e) =>
                    setFieldValue('nama_petugas_eksternal', e.target.value)
                  }
                  error={
                    errors?.nama_petugas_eksternal &&
                    touched?.nama_petugas_eksternal
                  }
                  errorText={errors?.nama_petugas_eksternal}
                />

                <SelectSearch
                  label="Vendor"
                  option={dropdownVendor?.data ?? []}
                  loading={dropdownVendor?.isLoading}
                  defaultValue={
                    values?.id_vendor_
                      ? dropdownVendor?.data?.find(
                          (e) => e.value === values.id_vendor,
                        )
                      : null
                  }
                  onChange={(e) => setFieldValue('id_vendor', e.value)}
                  error={errors?.id_vendor && touched?.id_vendor}
                  errorText={touched.id_vendor ? errors?.id_vendor : null}
                />
              </>
            ) : (
              <SelectSearch
                label="Petugas Maintenance"
                option={dropdownKaryawan?.data ?? []}
                loading={dropdownKaryawan?.isLoading}
                defaultValue={
                  values?.id_petugas_maintenance
                    ? dropdownKaryawan?.data?.find(
                        (e) => e.value === values.id_petugas_maintenance,
                      )
                    : null
                }
                onChange={(e) =>
                  setFieldValue('id_petugas_maintenance', e.value)
                }
                error={
                  errors?.id_petugas_maintenance &&
                  touched?.id_petugas_maintenance
                }
                errorText={
                  touched.id_petugas_maintenance
                    ? errors?.id_petugas_maintenance
                    : null
                }
              />
            )}

            <TableRincianPekerjaan createable />

            <TabRincianBiaya createable />

            <TextArea
              label="Keterangan"
              rows={4}
              placeholder="Keterangan"
              onChange={(e) => setFieldValue('keterangan', e.target.value)}
            />

            <TableSummaryBiaya />
          </Modal.Body>

          <Modal.Footer>
            <Row className="d-flex justify-content-end align-items-center">
              <ActionButton
                className="mr-2"
                variant="light"
                text="Kembali"
                onClick={onClose}
              />
              <ActionButton
                loading={isSubmitting}
                variant="primary"
                text="Simpan"
                onClick={handleSubmit}
              />
            </Row>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
