import { useContext } from 'react';
import { useFormikContext } from 'formik';
import {
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  DataStatus,
  InputCurrency,
} from 'components';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { RealisasiWorkOrderContext } from '../context';

export const TabBahan = ({ readOnly = false }) => {
  const { values, setFieldValue } = useFormikContext();
  const { dataHistory } = useContext(RealisasiWorkOrderContext);

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        List Data Bahan
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th width={140}>Informasi Transfer Bahan Work Order</Th>
            <Th>Item Bahan</Th>
            <Th width={110}>Satuan</Th>
            {!readOnly && (
              <>
                <Th width={110}>Qty. Transfer Bahan Produksi</Th>
                <Th width={110}>Qty. Terealisasi</Th>
              </>
            )}
            <Th width={110}>Qty. Realisasi</Th>
            <Th width={110}>Harga Satuan</Th>
            <Th width={110}>Nominal Realisasi</Th>
          </Tr>
        </THead>

        <TBody>
          {values?.bahan?.length ? (
            values?.bahan?.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{index + 1}</TdFixed>

                <Td>
                  <div>
                    {val.tgl_transfer_work_order_bahan
                      ? DateConvert(new Date(val.tgl_transfer_work_order_bahan))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_transfer_work_order_bahan}</div>
                </Td>

                <Td>{val.nama_item ?? '-'}</Td>

                <Td>{val.nama_satuan}</Td>

                {!readOnly && (
                  <>
                    <Td textRight>
                      {parseFloat(val?.qty_transfer_work_order_bahan ?? 0)}
                    </Td>

                    <Td textRight>{parseFloat(val?.qty_terealisasi ?? 0)}</Td>
                  </>
                )}

                {console.log(val)}

                <Td textRight noPadding={!readOnly}>
                  {readOnly ? (
                    `${parseFloat(val.qty_realisasi_bahan ?? 0)}`
                  ) : (
                    <InputCurrency
                      noMargin
                      prefix=""
                      allowLeadingZeros={false}
                      className="text-right m-0"
                      wrapperClassName="m-0"
                      value={values?.bahan?.[index]?.qty_realisasi_bahan ?? 0}
                      disabled={val.maxQty === 0}
                      isAllowed={(e) => {
                        const { floatValue } = e;
                        return floatValue <= val.maxQty;
                      }}
                      onChange={(e) =>
                        setFieldValue(`bahan.${index}.qty_realisasi_bahan`, e)
                      }
                    />
                  )}
                </Td>

                <Td textRight noPadding={!readOnly}>
                  {readOnly ? (
                    `${
                      RupiahConvert(
                        String(parseFloat(val.harga_satuan_bahan ?? 0))
                      ).getWithComa
                    }`
                  ) : (
                    <InputCurrency
                      noMargin
                      allowLeadingZeros={false}
                      className="text-right m-0"
                      wrapperClassName="m-0"
                      value={values?.bahan?.[index]?.harga_satuan_bahan ?? 0}
                      onChange={(e) =>
                        setFieldValue(`bahan.${index}.harga_satuan_bahan`, e)
                      }
                    />
                  )}
                </Td>

                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val?.qty_realisasi_bahan ?? 0) *
                          parseFloat(val?.harga_satuan_bahan ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>

      {!readOnly && (
        <>
          <div className="font-weight-bold" style={{ fontSize: '14px' }}>
            History Realisasi Bahan Work Order
          </div>

          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <Th width={140}>Informasi Realisasi Work Order</Th>
                <Th width={140}>Informasi Transfer Bahan Work Order</Th>
                <Th>Item Bahan</Th>
                <Th width={110}>Satuan</Th>
                <Th width={110}>Qty. Realisasi</Th>
                <Th width={110}>Harga Satuan</Th>
                <Th width={110}>Nominal Realisasi</Th>
              </Tr>
            </THead>

            <TBody>
              {dataHistory?.bahan?.length ? (
                dataHistory?.bahan?.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed textCenter>{index + 1}</TdFixed>

                    <Td>
                      <div>
                        {val.tgl_realisasi_work_order
                          ? DateConvert(new Date(val.tgl_realisasi_work_order))
                              .defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_realisasi_work_order}</div>
                    </Td>

                    <Td>
                      <div>
                        {val.tgl_transfer_work_order_bahan
                          ? DateConvert(
                              new Date(val.tgl_transfer_work_order_bahan)
                            ).defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_transfer_work_order_bahan}</div>
                    </Td>

                    <Td>{val.nama_item ?? '-'}</Td>

                    <Td>{val.nama_satuan}</Td>

                    <Td textRight>
                      {
                        DecimalConvert(val?.qty_realisasi_bahan ?? 0)
                          .getWithComa
                      }
                    </Td>

                    <Td textRight>
                      {
                        RupiahConvert(
                          String(parseFloat(val?.harga_satuan_bahan ?? 0))
                        ).getWithComa
                      }
                    </Td>

                    <Td textRight>
                      {
                        RupiahConvert(
                          String(
                            parseFloat(val?.qty_realisasi_bahan ?? 0) *
                              parseFloat(val?.harga_satuan_bahan ?? 0)
                          )
                        ).getWithComa
                      }
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={9}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}

              {!!dataHistory?.bahan?.length && (
                <Tr className="font-weight-bold">
                  <Td textRight colSpan={7}>
                    Total
                  </Td>

                  <Td textRight>
                    {
                      RupiahConvert(
                        String(calcTotalHistoryBahan(dataHistory?.bahan ?? []))
                      ).getWithComa
                    }
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
        </>
      )}
    </>
  );
};

export const calcTotalHistoryBahan = (data) => {
  return data?.reduce(
    (acc, val) =>
      acc +
      parseFloat(val.qty_realisasi_bahan ?? 0) *
        parseFloat(val.harga_satuan_bahan ?? 0),
    0
  );
};
