import {
  ButtonCreate,
  ButtonDelete,
  ButtonUpdate,
} from '@bhawanadevteam/react-core';
import {
  ActionButton,
  DataStatus,
  Input,
  SelectSearch,
  TBody,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from 'components';
import Thead from 'components/Table/THead';
import { Formik, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { DecimalConvert } from 'utilities';
import * as Yup from 'yup';
import { ModalFormBuaso } from './ModalFormBuaso';
import PermintaanWorkOrderApi from '../__PermintaanWorkOrderApi__';
import { useMutation } from 'react-query';

export const TableBuasoPermintaanWorkOrder = ({
  dataTable,
  type,
  action,
  setAlertConfig,
  alertConfig,
  refetch,
  setDefaultTab,
}) => {
  const { values } = useFormikContext();

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
  });

  const createPermintaanWorkOrderBuaso = useMutation((data) =>
    PermintaanWorkOrderApi.create(data)
  );

  const typeAlatMesinCheck = type === 'alat mesin' ? 'alat_mesin' : type;

  const modalFormInitialValues = {
    id_buaso:
      typeAlatMesinCheck === 'bahan'
        ? 1
        : typeAlatMesinCheck === 'upah'
        ? 2
        : typeAlatMesinCheck === 'alat_mesin'
        ? 3
        : typeAlatMesinCheck === 'subkon'
        ? 4
        : 5,
    tgl_permintaan_work_order: null,
    no_permintaan_work_order: '',
    qty_permintaan_work_order: 0,
    id_work_order: values.id_work_order ?? undefined,
    id_item: type === 'overhead' && 0,
  };

  const modalFormValidationSchema =
    type === 'overhead'
      ? Yup.object().shape({
          tgl_permintaan_work_order: Yup.string()
            .required('Masukan tanggal')
            .nullable(),
        })
      : Yup.object().shape({
          tgl_permintaan_work_order: Yup.string()
            .required('Masukan tanggal')
            .nullable(),
          qty_permintaan_work_order: Yup.string()
            .test(
              'maxQty',
              'Qty. Permintaan Melebihi Qty. Work Order',
              (value) => {
                const maxQty =
                  parseFloat(
                    modalConfig?.data?.[
                      `qty_work_order_${type === 'alat mesin' ? 'aset' : type}`
                    ]
                  ) - parseFloat(modalConfig?.data?.qty_diminta);

                return value > maxQty ? false : true;
              }
            )
            .required('Masukan qty'),
          id_item: Yup.string().required('Masukan item'),
        });

  const modalFormSubmitHandler = (value, { setSubmitting }) => {
    createPermintaanWorkOrderBuaso
      .mutateAsync(value)
      .then(() =>
        setAlertConfig({
          variant: 'primary',
          show: true,
          text: 'Data berhasil disimpan!!!',
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal disimpan!!!',
        })
      )
      .finally(() => {
        refetch();
        setSubmitting(false);
        setModalConfig({ show: false, data: {} });
        setDefaultTab(type);
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <small className="font-weight-bold text-capitalize mt-2">
          List Data {type}
        </small>
        {type === 'overhead' && (
          <ButtonCreate
            text="PERMINTAAN"
            variant="primary"
            onClick={() => setModalConfig({ show: true, data: values?.[type] })}
          />
        )}
      </div>
      <Table>
        <Thead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-capitalize">Kode {type}</ThFixed>
            <Th className="text-capitalize">Item {type}</Th>
            <Th>Qty.</Th>
            {typeAlatMesinCheck !== 'overhead' && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </Thead>
        <TBody>
          {values?.[typeAlatMesinCheck]?.length > 0 ? (
            values?.[typeAlatMesinCheck]?.map((val, ind) => (
              <Tr key={ind}>
                <Td>{ind + 1}</Td>
                <Td>
                  {typeAlatMesinCheck === 'alat_mesin'
                    ? val.kode_item_aset
                    : val.kode_item}
                </Td>
                <Td>
                  {typeAlatMesinCheck === 'alat_mesin'
                    ? val.nama_item_aset
                    : val.nama_item}
                </Td>
                <Td className="text-right">
                  {
                    DecimalConvert(
                      parseFloat(
                        val?.[`qty_work_order_${typeAlatMesinCheck}`] ?? 0
                      )
                    ).getWithComa
                  }
                </Td>
                {typeAlatMesinCheck !== 'overhead' && (
                  <TdFixed>
                    <ButtonGroup>
                      <ButtonCreate
                        noText
                        icon
                        size="sm"
                        onClick={() =>
                          setModalConfig({ show: true, data: val })
                        }
                      />
                    </ButtonGroup>
                  </TdFixed>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="5">
                <DataStatus text="Data Tidak Ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>

      {modalConfig.show && (
        <Formik
          initialValues={modalFormInitialValues}
          validationSchema={modalFormValidationSchema}
          onSubmit={modalFormSubmitHandler}
        >
          <ModalFormBuaso
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
            type={type}
            typeAlatMesinCheck={typeAlatMesinCheck}
          />
        </Formik>
      )}
    </div>
  );
};
