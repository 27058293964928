// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

// Components
import { Button, Modal } from "react-bootstrap";
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  TdFixed,
  DataStatus,
  Tr,
  BackButton,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
  ThFixed,
  ReadButton,
  FilterButton,
} from "components";
import { PageNumber, DateConvert, DecimalConvert } from "utilities";

// API
import { TransferAlatMesinJobMixApi } from "api";

// Icons
import { IoAddOutline } from "react-icons/io5";
import { ModalFilterProduksi, DetailProduksi } from "./components";

const ListPermintaanAlatMesinJobMix = ({ setNavbarTitle }) => {
  // USE HISTORY
  const history = useHistory();
  const location = useLocation();

  // Variables
  const title = "Transfer Alat & Mesin Produksi";
  const filter = location.state?.filter ?? {};

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // data jenis
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState("");
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.post?.active,
    page: filter.post?.page ?? 1,
    per_page: filter.post?.per_page ?? 10,
    q: filter.post?.q ?? "",
    tgl_jobmix_awal: filter.post?.tgl_jobmix_awal,
    tgl_jobmix_akhir: filter.post?.tgl_jobmix_akhir,
    id_item_jobmix: filter.post?.id_item_jobmix,
    id_item_diminta: filter.post?.id_item_diminta,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [collapsedIndex, setCollapsedIndex] = useState(-1);
  const [modal, setModal] = useState(false);
  const [id, setId] = useState(null);

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    const filterData = { ...dataFilter };
    delete filterData.active;

    TransferAlatMesinJobMixApi.produksi_page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setDataFilter({
      ...dataFilter,
      page: 1,
      q: key,
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle(title);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCollapsedIndex(-1);
    getData();

    // eslint-disable-next-line
  }, [dataFilter]);

  const toggleModalFilter = () => setModalFilter(!modalFilter);
  const toggleModal = () => setModal(!modal);

  const DetailModal = () => (
    <Modal show={true} onHide={toggleModal} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">Detail Data Produksi</h6>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <DetailProduksi id={id} />
      </Modal.Body>
    </Modal>
  );

  // Tabel
  const Table = () => (
    <>
      <b>List Produksi Yang Siap Dibuatkan {title}</b>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Produksi</ThFixed>
            <Th>Item Yang Diproduksi</Th>
            <ThFixed>Qty. Produksi</ThFixed>
            <Th>Item Alat & Mesin Yang Diminta</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {PageNumber(dataFilter.page, dataFilter.per_page, index)}
              </TdFixed>
              <Td>
                <div>
                  {val.tgl_jobmix
                    ? DateConvert(new Date(val.tgl_jobmix)).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_jobmix ?? "-"}</div>
              </Td>
              <Td>
                {val.nama_item ?? "-"}{" "}
                {val.nama_varian ? `[${val.nama_varian}]` : ""}
              </Td>
              <Td className="text-right">{`${
                DecimalConvert(val.qty_jobmix ?? 0).getWithComa
              } ${val.kode_satuan ?? ""}`}</Td>
              <Td>
                {val.permintaan.length === 0 ? (
                  "-"
                ) : (
                  <>
                    <ul className="pl-3 m-0">
                      {val.permintaan
                        .filter((_val, indexPermintaan) => {
                          if (index === collapsedIndex) {
                            return indexPermintaan <= val.permintaan.length;
                          } else {
                            return indexPermintaan <= 2;
                          }
                        })
                        .map((val, index) => (
                          <li key={index}>
                            {val.nama_item ?? "-"}{" "}
                            {`${
                              DecimalConvert(val.qty_permintaan_produksi ?? 0)
                                .getWithComa
                            } ${val.kode_satuan ?? ""}`}
                          </li>
                        ))}
                    </ul>
                    {val.permintaan.length > 3 && index !== collapsedIndex && (
                      <Button
                        variant="link"
                        className="pt-0"
                        onClick={() => setCollapsedIndex(index)}
                      >
                        Selengkapnya...
                      </Button>
                    )}
                  </>
                )}
              </Td>
              <TdFixed>
                <div className="d-flex">
                  <ReadButton
                    size="sm"
                    className="my-1"
                    onClick={() => {
                      setId(val.id_jobmix);
                      toggleModal();
                    }}
                  />

                  <ActionButton
                    text={<IoAddOutline size={12} />}
                    className="my-1"
                    size="sm"
                    onClick={() => {
                      history.push(
                        `/transaksi/transfer-alat-mesin-job-mix/tambah/${val.id_jobmix}`,
                        {
                          filter: {
                            ...filter,
                            post: dataFilter,
                          },
                        }
                      );
                    }}
                  />
                </div>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={dataFilter.per_page}
        dataPage={
          totalData <= 10
            ? data.length
            : data.map((_res, index) => {
                if (index === data.length - 1) {
                  return PageNumber(
                    dataFilter.page,
                    dataFilter.per_page,
                    index
                  );
                } else {
                  return null;
                }
              })
        }
        dataNumber={data.map((_res, index) => {
          if (index === 0) {
            return PageNumber(dataFilter.page, dataFilter.per_page, index);
          } else {
            return null;
          }
        })}
        dataCount={totalData}
        onDataLengthChange={(e) => {
          setDataFilter({
            ...dataFilter,
            per_page: e.target.value,
            page: 1,
          });
        }}
        currentPage={dataFilter.page}
        totalPage={totalPage}
        onPaginationChange={({ selected }) =>
          setDataFilter({
            ...dataFilter,
            page: selected + 1,
          })
        }
      />
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={dataFilter?.q} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.active}
              onClick={toggleModalFilter}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() => {
              history.push("/transaksi/transfer-alat-mesin-job-mix", {
                filter: {
                  ...filter,
                  post: dataFilter,
                },
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {/* Table Section */}
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilterProduksi
          toggle={toggleModalFilter}
          data={dataFilter}
          setData={setDataFilter}
          buaso="alat_mesin"
        />
      )}
      {modal && <DetailModal />}
    </CRUDLayout>
  );
};

export default ListPermintaanAlatMesinJobMix;
