import totalSummary from "./totalSummary"

const dataSummary = (values) => {
    return [
        {
            buaso: "Bahan",
            summary_ut: totalSummary('bahan', 'UT', values),
            summary_tb: totalSummary('bahan', 'TB', values),
            total: totalSummary('bahan', 'UT', values) + totalSummary('bahan', 'TB', values)
        },
        {
            buaso: "Upah",
            summary_ut: totalSummary('upah', 'UT', values),
            summary_tb: totalSummary('upah', 'TB', values),
            total: totalSummary('upah', 'UT', values) + totalSummary('upah', 'TB', values)
        },
        {
            buaso: "Alat & Mesin",
            summary_ut: totalSummary('alat_mesin', 'UT', values),
            summary_tb: totalSummary('alat_mesin', 'TB', values),
            total: totalSummary('alat_mesin', 'UT', values) + totalSummary('alat_mesin', 'TB', values)
        },
        {
            buaso: "Subkon",
            summary_ut: totalSummary('subcont', 'UT', values),
            summary_tb: totalSummary('subcont', 'TB', values),
            total: totalSummary('subcont', 'UT', values) + totalSummary('subcont', 'TB', values)
        },
        {
            buaso: "Overhead",
            summary_ut: totalSummary('overhead', 'UT', values),
            summary_tb: totalSummary('overhead', 'TB', values),
            total: totalSummary('overhead', 'UT', values) + totalSummary('overhead', 'TB', values)
        }
    ]
}

export default dataSummary