import { InfoItemHorizontal } from 'components';
import { useFormikContext } from 'formik';
import React from 'react';
import { Card, Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { TableBuasoPermintaanWorkOrder } from './TableBuasoPermintaanWorkOrder';
import { TableHistoryBuasoPermintaanWorkOrder } from './TableHistoryBuasoPermintaanWorkOrder';
import { DateConvert } from 'utilities';
import { IoWarningSharp } from 'react-icons/io5';
import { Button } from '@bhawanadevteam/react-core';

export const ModalConfirm = ({ setModalConfig, modalConfig }) => {
  const { values, handleSubmit, isSubmitting } = useFormikContext();

  return (
    <>
      <Modal
        show={modalConfig.show}
        onHide={() => setModalConfig({ show: false })}
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>Konfirmasi Status Permintaan</Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <IoWarningSharp color="#C93636" style={{ fontSize: 100 }} />
          </div>
          <div className="d-flex justify-content-center">
            <div className="font-weight-bold text-center">
              Apa Anda yakin ingin mengganti status data ini ? Status yang telah
              <br />
              diganti tidak bisa di edit!
            </div>
          </div>
          <small className="font-weight-bold">Detail Data Work Order</small>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <InfoItemHorizontal
                    label="Tgl. Work Order"
                    text={
                      values?.tgl_work_order
                        ? DateConvert(new Date(values?.tgl_work_order)).detail
                        : '-'
                    }
                  />
                  <InfoItemHorizontal
                    label="No. Work Order"
                    text={values?.no_work_order ?? '-'}
                  />
                  <InfoItemHorizontal
                    label="Item Aset"
                    text={values?.nama_item_aset ?? '-'}
                  />
                  <InfoItemHorizontal
                    label="Petugas Maintenance"
                    text={values?.nama_petugas ?? '-'}
                  />
                  <InfoItemHorizontal
                    label="Status Petugas"
                    text={
                      values?.is_mekanik_eksternal
                        ? 'Mekanik Eksternal'
                        : 'Mekanik Internal'
                    }
                  />
                  <InfoItemHorizontal
                    label="Vendor"
                    text={values?.nama_vendor ?? '-'}
                  />
                </Col>
                <Col>
                  <InfoItemHorizontal
                    label="Tgl. Maintenance Request"
                    text={
                      values?.tgl_maintenance_request_master
                        ? DateConvert(
                            new Date(values?.tgl_maintenance_request_master)
                          ).detail
                        : '-'
                    }
                  />
                  <InfoItemHorizontal
                    label="No. Maintenance Request"
                    text={values?.no_maintenance_request_master ?? '-'}
                  />
                  <InfoItemHorizontal
                    label="Catatan"
                    text={values?.catatan_maintenance_request ?? '-'}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card className="p-0 mt-3">
            <Tab.Container defaultActiveKey="bahan">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="bahan">Bahan</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="upah">Upah</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="alat_mesin">Alat Mesin</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="subkon">Subkon</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="overhead">Overhead</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content>
                <Tab.Pane eventKey="bahan" className="p-2">
                  <TableHistoryBuasoPermintaanWorkOrder type="bahan" />
                </Tab.Pane>
                <Tab.Pane eventKey="upah" className="p-2">
                  <TableHistoryBuasoPermintaanWorkOrder type="upah" />
                </Tab.Pane>
                <Tab.Pane eventKey="alat_mesin" className="p-2">
                  <TableHistoryBuasoPermintaanWorkOrder type="alat mesin" />
                </Tab.Pane>
                <Tab.Pane eventKey="subkon" className="p-2">
                  <TableHistoryBuasoPermintaanWorkOrder type="subkon" />
                </Tab.Pane>
                <Tab.Pane eventKey="overhead" className="p-2">
                  <TableHistoryBuasoPermintaanWorkOrder type="overhead" />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" size="sm" text="BATAL" />
          <Button
            variant="danger"
            size="sm"
            text="SELESAI PERMINTAAN"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};
