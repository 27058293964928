import Services from "services";

class TransferProduksiAlatMesinApi {
    get(params) {
      return Services.get('/jobmix_transfer_alat_mesin/page', {params})
    }

    getPermintaan(params) {
      return Services.get('/jobmix_transfer_alat_mesin/permintaan', {params})
    }
    
    getSingle(params) {
      return Services.get('/jobmix_transfer_alat_mesin/permintaan_single', {params})
    }

    getNomor(params) {
      return Services.get('/jobmix_transfer_alat_mesin/no_baru', {params})
    }

    save(data) {
      return Services.post('/jobmix_transfer_alat_mesin', data)
    }
}

export default new TransferProduksiAlatMesinApi();
