export const formInitialValues = (data) => {
  return Object({
    id_maintenance_request_master:
      data?.id_maintenance_request_master ?? undefined,
    tgl_maintenance_request_master:
      data?.tgl_maintenance_request_master ?? undefined,
    no_maintenance_request_master: data?.no_maintenance_request_master ?? "",
    catatan: data?.catatan ?? "",
    detail: data?.detail
      ? data.detail.map((val) => ({
          namaGrupAset: val?.nama_grup_aset ?? "",
          namaKelompokAset: val?.nama_kategori_aset ?? "",
          namaJenisAset: val?.nama_jenis_aset ?? "",
          namaItemAset: val?.nama_item_aset ?? "",
          kodeItemAset: val?.kode_item_aset ?? "",
          idItemAset: val?.id_item_aset ?? undefined,
          keterangan: val?.keterangan ?? "",
        }))
      : [],
  });
};
