import Services from '../../../services'

class JenisAsetApi {
    getPage(page, dataLength, key) {
        return Services.get(`/jenisaset/page/?page=${page}&per_page=${dataLength}&q=${key}`);
    }
    dropdown(value) {
        return Services.get(`/jenisaset/dropdown`, {params:{...value}});
    }
    single(value) {
        return Services.get(`/jenisaset/single`, {params:{...value}});
    }
    create(data) {
        return Services.post("/jenisaset", data);
    }
    update(data) {
        return Services.put("/jenisaset", data);
    }
    delete(value) {
        return Services.post("/jenisaset/delete", value);
    }
    show(value) {
        return Services.put("/jenisaset/show", value);
    }
    hide(value) {
        return Services.put("/jenisaset/hide", value);
    }
}

export default new JenisAsetApi();