import { ButtonCancel } from '@bhawanadevteam/react-core';
import { InfoItemHorizontal, InfoItemVertical } from 'components';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';

export const ModalDetail = ({ setModalConfig, modalConfig }) => {
  return (
    <div>
      <Modal
        show={modalConfig.show}
        onHide={() => setModalConfig({ data: {}, show: false })}
      >
        <Modal.Header closeButton>
          Detail Transfer Alat Mesin Produksi Aset
        </Modal.Header>
        <Modal.Body>
          <InfoItemHorizontal
            width={200}
            label="Tgl. Permintaan Produksi Aset"
            text={
              modalConfig?.data?.tgl_permintaan_produksi_aset_alat_mesin
                ? DateConvert(
                    new Date(
                      modalConfig?.data?.tgl_permintaan_produksi_aset_alat_mesin
                    )
                  ).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            width={200}
            label="No. Permintaan Produksi Aset"
            text={
              modalConfig?.data?.no_permintaan_produksi_aset_alat_mesin ?? '-'
            }
          />
          <InfoItemHorizontal
            width={200}
            label="Item Alat Mesin"
            text={modalConfig?.data?.nama_item ?? '-'}
          />
          <InfoItemHorizontal
            width={200}
            label="Qty. Permintaan"
            text={`${
              DecimalConvert(
                parseFloat(modalConfig?.data?.qty_permintaan_produksi ?? 0)
              ).getWithComa
            } ${modalConfig?.data?.nama_satuan ?? '-'}`}
          />
          <hr />
          <Row>
            <Col>
              <InfoItemVertical
                label="Tgl. Transfer Alat Mesin Produksi Aset"
                text={
                  modalConfig?.data?.tgl_transfer_produksi_aset_alat_mesin
                    ? DateConvert(
                        new Date(
                          modalConfig?.data?.tgl_transfer_produksi_aset_alat_mesin
                        )
                      ).detail
                    : '-'
                }
              />
              <InfoItemVertical
                label="Qty. Transfer"
                text={`${
                  DecimalConvert(
                    parseFloat(modalConfig?.data?.qty_transfer ?? 0)
                  ).getWithComa
                } ${modalConfig?.data?.nama_satuan ?? '-'}`}
              />
              <InfoItemVertical
                label="Diserahkan Oleh"
                text={modalConfig?.data?.nama_karyawan}
              />
              <InfoItemVertical
                label="Keterangan"
                text={modalConfig?.data?.keterangan_transfer ?? '-'}
              />
            </Col>
            <Col>
              <InfoItemVertical
                label="No. Transfer Alat Mesin Produksi Aset"
                text={
                  modalConfig?.data?.no_transfer_produksi_aset_alat_mesin ?? '-'
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonCancel
            text="Tutup"
            onClick={() => setModalConfig({ data: {}, show: false })}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};
