// React
import React from 'react';

// Form
import { Formik, useFormikContext } from 'formik';

// Components
import { DatePicker, FilterModal } from 'components';
import { Select } from 'components2';

// API
import { useQuery } from 'react-query';
import PermintaanWorkOrderApi from '../__PermintaanWorkOrderApi__';
import { DateConvert } from 'utilities';

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  const { data: itemAset, isFetching: loadingItemAset } = useQuery(
    ['itemAset', 'dropdown'],
    () => PermintaanWorkOrderApi.getDropdownItemFilter({ id_buaso: 3 })
  );

  const { data: statusPermintaan, isFetching: loadingStatusPermintaan } =
    useQuery(['statusPermintaan', 'dropdown'], () =>
      PermintaanWorkOrderApi.getDropdownStatusPermintaan()
    );

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_work_order_mulai: undefined,
        tgl_work_order_selesai: undefined,
        tgl_maintenance_mulai: undefined,
        tgl_maintenance_selesai: undefined,
        id_item_aset: undefined,
        selesai_permintaan: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Maintenance Request"
        placeholderText="Pilih tgl. maintenance request"
        startDate={
          values?.tgl_maintenance_mulai
            ? new Date(values?.tgl_maintenance_mulai)
            : ''
        }
        endDate={
          values?.tgl_maintenance_selesai
            ? new Date(values?.tgl_maintenance_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_maintenance_mulai',
            'tgl_maintenance_selesai'
          )
        }
        monthsShown={2}
      />

      <DatePicker
        selectsRange
        label="Tgl. Work Order"
        placeholderText="Pilih tgl. work order"
        startDate={
          values?.tgl_work_order_mulai
            ? new Date(values?.tgl_work_order_mulai)
            : ''
        }
        endDate={
          values?.tgl_work_order_selesai
            ? new Date(values?.tgl_work_order_selesai)
            : ''
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            'tgl_work_order_mulai',
            'tgl_work_order_selesai'
          )
        }
        monthsShown={2}
      />

      <Select
        label="Item Aset"
        placeholder="Pilih item aset"
        name="id_item_aset"
        options={itemAset ?? []}
        loading={loadingItemAset}
        onChange={async (val) =>
          setValues({
            ...values,
            id_item_aset: val.value,
          })
        }
        defaultValue={itemAset?.find(
          (val) => val.value === values.id_item_aset
        )}
      />

      <Select
        label="Status Permintaan"
        placeholder="Pilih status permintaan"
        name="selesai_permintaan"
        options={statusPermintaan ?? []}
        loading={loadingStatusPermintaan}
        onChange={async (val) =>
          setValues({
            ...values,
            selesai_permintaan: val.value,
          })
        }
        defaultValue={statusPermintaan?.find(
          (val) => val.value === values.selesai_permintaan
        )}
      />
    </FilterModal>
  );
};
