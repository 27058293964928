import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { range } from 'lodash';
import Axios from 'axios';
import { AsetMesinDanAlatApi, RekapitulasiPenyusutanAsetApi } from 'api';

const ModalFilter = ({ show, setShow, data, setData }) => {
  // STATE DATA
  const [loading, setLoading] = useState(true);

  const yearNow = DateConvert(new Date()).defaultYear;
  const yearOptions = range(2000, parseInt(yearNow + 1)).map((val) => ({
    label: val,
    value: val,
  }));
  const [dataGrup, setDataGrup] = useState([]);
  const [dataKategori, setDataKategori] = useState([]);
  const [dataJenis, setDataJenis] = useState([]);

  const [dataItem, setDataItem] = useState([]);
  const [isGroupAsetChange, setIsGroupAsetChange] = useState({
    disable: true,
    loading: false,
  });
  const [isKategoriAsetChange, setIsKategoriAsetChange] = useState({
    disable: true,
    loading: false,
  });
  const [isJenisAsetChange, setIsJenisAsetChange] = useState({
    disable: true,
    loading: false,
  });

  const monthOptions = [
    { label: 'Januari', value: '01' },
    { label: 'Februari', value: '02' },
    { label: 'Maret', value: '03' },
    { label: 'April', value: '04' },
    { label: 'Mei', value: '05' },
    { label: 'Juni', value: '06' },
    { label: 'Juli', value: '07' },
    { label: 'Agustus', value: '08' },
    { label: 'September', value: '09' },
    { label: 'Oktober', value: '10' },
    { label: 'November', value: '11' },
    { label: 'Desember', value: '12' },
  ];

  // REQUSET DATA SERVER
  const getDataDropdown = () => {
    Axios.all([RekapitulasiPenyusutanAsetApi.getDropdown({ tipe: 'grup' })])
      .then(
        Axios.spread((grup) => {
          const mapDataGrupAset = grup?.data?.data
            ? grup.data.data.map((val) => ({
                label: val?.nama_grup_aset,
                value: val?.id_grup_aset,
              }))
            : [];
          setDataGrup(mapDataGrupAset);
        })
      )
      .finally(() => setLoading(false));
  };

  const groupAsetChange = (id) => {
    setIsGroupAsetChange({ disable: true, loading: true });
    Axios.all([
      RekapitulasiPenyusutanAsetApi.getDropdown({
        tipe: 'kategori',
        id_grup_aset: id,
      }),
    ])
      .then(
        Axios.spread((grup) => {
          const mapDataKategoriAset = grup?.data?.data
            ? grup.data.data.map((val) => ({
                label: val?.nama_kategori_aset,
                value: val?.id_kategori_aset,
              }))
            : [];
          setDataKategori(mapDataKategoriAset);
        })
      )
      .finally(() => {
        setIsGroupAsetChange({ disable: false, loading: false });
      });
  };

  const KategoriAsetChange = (id) => {
    setIsKategoriAsetChange({ disable: true, loading: true });
    Axios.all([
      RekapitulasiPenyusutanAsetApi.getDropdown({
        tipe: 'jenis',
        id_kategori_aset: id,
      }),
    ])
      .then(
        Axios.spread((grup) => {
          const mapDataJenisAset = grup?.data?.data
            ? grup.data.data.map((val) => ({
                label: val?.nama_jenis_aset,
                value: val?.id_jenis_aset,
              }))
            : [];
          setDataJenis(mapDataJenisAset);
        })
      )
      .finally(() => {
        setIsKategoriAsetChange({ disable: false, loading: false });
      });
  };

  const ItemAsetChange = (id) => {
    setIsJenisAsetChange({ disable: true, loading: true });
    Axios.all([
      RekapitulasiPenyusutanAsetApi.getDropdown({
        tipe: 'item',
        id_jenis_aset: id,
      }),
    ])
      .then(
        Axios.spread((grup) => {
          const mapDataItemAset = grup?.data?.data
            ? grup.data.data.map((val) => ({
                label: val?.nama_item_aset,
                value: val?.id_item_aset,
              }))
            : [];
          setDataItem(mapDataItemAset);
        })
      )
      .finally(() => {
        setIsJenisAsetChange({ disable: false, loading: false });
      });
  };

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    getDataDropdown();
  }, []);
  // FORM VALUES
  const formInitialValues = {
    id_grup_aset: data?.filter?.id_grup_aset,
    id_kategori_aset: data?.filter?.id_kategori_aset,
    id_jenis_aset: data?.filter?.id_jenis_aset,
    id_item_aset: data?.filter?.id_item_aset,
    bulan_perolehan: data?.filter?.bulan_perolehan,
    tahun_perolehan: data?.filter?.tahun_perolehan,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  const onResetButtonClick = (values, setValues) => {
    setValues({
      id_grup_aset: undefined,
      id_kategori_aset: undefined,
      id_jenis_aset: undefined,
      id_item_aset: undefined,
      bulan_perolehan: undefined,
      tahun_perolehan: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            key={values.tahun_perolehan}
            label="Tahun"
            placeholder="Pilih tahun perolehan"
            defaultValue={yearOptions.find(
              (val) => val.value === values.tahun_perolehan
            )}
            option={yearOptions}
            onChange={(val) => {
              setFieldValue('tahun_perolehan', val.value);
            }}
          />
          <SelectSearch
            key={values.bulan_perolehan}
            label="Bulan"
            placeholder="Pilih bulan perolehan"
            defaultValue={monthOptions.find(
              (val) => parseInt(val.value) === parseInt(values.bulan_perolehan)
            )}
            option={monthOptions}
            onChange={(val) => {
              setFieldValue('bulan_perolehan', val.value);
            }}
          />
          <SelectSearch
            // key={values.id_grup_aset}
            label="Grup Aset"
            placeholder="Pilih grup aset"
            defaultValue={dataGrup.find(
              (val) => val.value === values.id_grup_aset
            )}
            option={dataGrup}
            onChange={(val) => {
              groupAsetChange(val?.value);
              setFieldValue('id_grup_aset', val.value);
            }}
          />
          <SelectSearch
            // key={values.id_kategori_aset}
            label="Kategori Aset"
            placeholder="Pilih kategori aset"
            defaultValue={
              values?.id_kategori_aset &&
              dataKategori.find(
                (val) => val?.value === values?.id_kategori_aset
              )
            }
            isDisabled={isGroupAsetChange.disable}
            loading={isGroupAsetChange.loading}
            option={dataKategori}
            onChange={(val) => {
              KategoriAsetChange(val?.value);
              setFieldValue('id_kategori_aset', val.value);
            }}
          />
          <SelectSearch
            // key={values.id_jenis_aset}
            label="Jenis Aset"
            placeholder="Pilih jenis aset"
            defaultValue={
              values?.id_jenis_aset &&
              dataJenis.find((val) => val?.value === values?.id_jenis_aset)
            }
            isDisabled={isKategoriAsetChange.disable}
            loading={isKategoriAsetChange.loading}
            option={dataJenis}
            onChange={(val) => {
              ItemAsetChange(val?.value);
              setFieldValue('id_jenis_aset', val.value);
            }}
          />
          <SelectSearch
            // key={values.id_item_aset}
            label="Item Aset"
            placeholder="Pilih item aset"
            defaultValue={
              values?.id_item_aset &&
              dataItem.find((val) => val?.value === values?.id_item_aset)
            }
            isDisabled={isJenisAsetChange.disable}
            loading={isJenisAsetChange.loading}
            option={dataItem}
            onChange={(val) => {
              // KategoriAsetChange(val?.value);
              setFieldValue('id_item_aset', val.value);
            }}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
