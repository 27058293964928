import { useState, useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { CloseWorkOrderContext } from '../context';

export const ModalFilterPreformPage = ({ toggle, data, setData }) => {
  const [loading, setLoading] = useState(false);
  const { dropdownAset } = useContext(CloseWorkOrderContext);
  const option = { label: 'Semua Data', value: undefined };
  const optionAset = [option].concat(dropdownAset?.data ?? []);

  const formInitialValues = {
    tgl_maintenance_request_start: data.tgl_maintenance_request_start,
    tgl_maintenance_request_end: data.tgl_maintenance_request_end,
    tgl_work_order_start: data.tgl_work_order_start,
    tgl_work_order_end: data.tgl_work_order_end,
    id_item_aset: data.id_item_aset,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_maintenance_request_start: undefined,
        tgl_maintenance_request_end: undefined,
        tgl_work_order_start: undefined,
        tgl_work_order_end: undefined,
        id_item_aset: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Maintenance Request"
            placeholderText="Pilih Tanggal Maintenance Request"
            startDate={
              values?.tgl_maintenance_request_start
                ? new Date(values?.tgl_maintenance_request_start)
                : ''
            }
            endDate={
              values?.tgl_maintenance_request_end
                ? new Date(values?.tgl_maintenance_request_end)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_maintenance_request_start`,
                `tgl_maintenance_request_end`
              )
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. Work Order"
            placeholderText="Pilih Tanggal Work Order"
            startDate={
              values?.tgl_work_order_start
                ? new Date(values?.tgl_work_order_start)
                : ''
            }
            endDate={
              values?.tgl_work_order_end
                ? new Date(values?.tgl_work_order_end)
                : ''
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_work_order_start`,
                `tgl_work_order_end`
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="1"
            label="Item Aset"
            placeholder="Pilih Item Aset"
            defaultValue={optionAset?.find(
              (val) => val.value === values.id_item_aset
            )}
            option={optionAset}
            onChange={(val) => setFieldValue('id_item_aset', val.value)}
            loading={dropdownAset?.isLoading || loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
