import { Card, Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';

export const InfoSectionDetailWorkOrder = ({ data }) => {
  return (
    <>
      <div className="font-weight-bold " style={{ fontSize: '14px' }}>
        Detail Data Work Order
      </div>

      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md>
              <InfoItemHorizontal
                label="Tgl. Work Order"
                text={
                  data?.tgl_work_order
                    ? DateConvert(new Date(data?.tgl_work_order)).detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Work Order"
                text={data?.no_work_order ?? '-'}
              />
              <InfoItemHorizontal
                label="Item Aset"
                text={data?.nama_item_aset ?? '-'}
              />
              <InfoItemHorizontal
                label="Petugas Maintenance"
                text={data?.nama_petugas ?? '-'}
              />
              <InfoItemHorizontal
                label="Status Petugas"
                text={
                  data?.is_mekanik_eksternal
                    ? 'Mekanik Eksternal'
                    : 'Mekanik Internal'
                }
              />
              <InfoItemHorizontal label="Vendor" text={data?.nama_vendor} />
            </Col>

            <Col md>
              <InfoItemHorizontal
                label="Tgl. Maintenance Request"
                text={
                  data.tgl_maintenance_request_master
                    ? DateConvert(new Date(data.tgl_maintenance_request_master))
                        .detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Maintenance Request"
                text={data?.no_maintenance_request_master ?? '-'}
              />
              <InfoItemHorizontal
                label="Catatan"
                text={data?.catatan_maintenance_request ?? '-'}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
