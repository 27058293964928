import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormPermintaanAsetContent } from './__PermintaanAsetComps__';
import { useMutation, useQuery } from 'react-query';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import MaintenanceRequestApi from './__PenerimaanAsetApi__';
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from './__PermintaanAsetUtils__';
import { Alert, DataStatus } from 'components';

export const PenerimaanAsetUpdate = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const {
    data: detailPenerimaanAset,
    isFetching: loadingDetailPenerimaanAset,
  } = useQuery(
    ['permintaanAset', 'single', id],
    () =>
      MaintenanceRequestApi.getSingle({
        id_penerimaan_aset: id,
      }),
    { enabled: !!id }
  );

  const updatePenerimaanAset = useMutation((data) =>
    MaintenanceRequestApi.update(data)
  );
  const formSubmitHandler = (value, { setSubmitting }) => {
    updatePenerimaanAset
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push('/transaksi/penerimaan-aset', {
          alert: {
            variant: 'primary',
            show: true,
            text: 'Data berhasil disimpan!!!',
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: 'danger',
          show: true,
          text: 'Data gagal disimpan!!!',
        })
      )
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    setNavbarTitle('Penerimaan Aset');

    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailPenerimaanAset ? (
        <DataStatus loading text="Memuat Data..." />
      ) : (
        <Formik
          initialValues={formInitialValues(detailPenerimaanAset?.data)}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          <FormPermintaanAsetContent action="update" />
        </Formik>
      )}
    </>
  );
};
