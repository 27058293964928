import { Formik } from "formik";
import React, { useState } from "react";
import { FormAnalisaChecklistContent } from "./__MaintenanceRequestComps__";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MaintenanceRequestApi from "./__MaintenanceRequestApi__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__MaintenanceRequestUtils__";
import { Alert } from "components";

export const MaintenanceRequestCreate = () => {
  const history = useHistory();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const createMaintenanceRequest = useMutation((data) =>
    MaintenanceRequestApi.create(data)
  );
  const formSubmitHandler = (value) => {
    createMaintenanceRequest
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push("/transaksi/maintenance-request", {
          alert: {
            variant: "primary",
            show: true,
            text: "Data berhasil disimpan!!!",
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          show: true,
          text: "Data gagal disimpan!!!",
        })
      );
  };

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      <Formik
        initialValues={formInitialValues()}
        onSubmit={formSubmitHandler}
        validationSchema={formValidationSchema}
      >
        <FormAnalisaChecklistContent action="create" />
      </Formik>
    </>
  );
};
