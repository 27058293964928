import { useState, createContext } from 'react';
import { CloseWorkOrderApi } from 'api';
import { useQuery } from 'react-query';

export const CloseWorkOrderContext = createContext();

export const CloseWorkOrderProvider = ({ children }) => {
  const STALE_DURATION_MINUTES = 4 * 60 * 1000; // 4 MINUTES

  const [modalWorkOrder, setModalWorkOrder] = useState({
    show: false,
    data: null,
  });

  const dropdownAset = useQuery(
    ['dropdown', 'aset'],
    () =>
      CloseWorkOrderApi.getDropdownAset().then((res) => {
        const data = res.data.data.map((val) => ({
          ...val,
          value: val.id_item_aset,
          label: val.nama_item_aset,
        }));

        return data;
      }),

    { staleTime: STALE_DURATION_MINUTES }
  );

  return (
    <CloseWorkOrderContext.Provider
      value={{
        modalWorkOrder,
        setModalWorkOrder,
        dropdownAset,
      }}
    >
      {children}
    </CloseWorkOrderContext.Provider>
  );
};

export const closeWorkOrderProvider = (Component) => (props) => {
  return (
    <CloseWorkOrderProvider>
      <Component {...props} />
    </CloseWorkOrderProvider>
  );
};
