import { Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';

export const InfoMaintenanceRequest = ({ data }) => {
  return (
    <Row>
      <Col md>
        <InfoItemHorizontal
          label="Tgl. Maintenance Request"
          text={
            data?.tgl_maintenance_request_master
              ? DateConvert(new Date(data.tgl_maintenance_request_master))
                  .detail
              : '-'
          }
        />
        <InfoItemHorizontal
          label="No. Maintenance Request"
          text={data?.no_maintenance_request_master ?? '-'}
        />
        <InfoItemHorizontal
          label="Catatan"
          text={data?.catatan_maintenance_request ?? '-'}
        />
      </Col>
    </Row>
  );
};
