import React, { useState, useEffect } from "react";
import { ButtonGroup } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  BackButton,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
} from "components2";
import { tableNumber } from "utilities2";
import { ModalFilter } from "./__AnalisaChecklistComps__";
import ItemChecklistApi from "./__AnalisaChecklistApi__";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";

export const ItemAsetAnalisaChecklist = ({ setNavbarTitle }) => {
  const history = useHistory();

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    per_page: 10,
    id_grup_aset: undefined,
    id_kategori_aset: undefined,
    id_jenis_aset: undefined,
    id_item_aset: undefined,
    active: false,
  });
  const [modal, setModal] = useState({
    type: "CREATE", // CREATE | UPDATE | DETAIL
    show: false,
    data: {},
  });
  const [modalFilter, setModalFilter] = useState(false);

  const getItemAsetList = useQuery(
    ["item_aset_analisa_checklist", "list", filter],
    () => ItemChecklistApi.getListItemAset(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const formInitialValuesFilter = {
    id_grup_aset: filter?.id_grup_aset,
    id_kategori_aset: filter?.id_kategori_aset,
    id_jenis_aset: filter?.id_jenis_aset,
    id_item_aset: filter?.id_item_aset,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => setNavbarTitle("Analisa Checklist"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="sm"
            icon
            onClick={() => history.push("/asm/master/analisa-checklist")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getItemAsetList.isLoading || getItemAsetList.isError ? (
        <DataStatus
          loading={getItemAsetList.isLoading}
          text={
            getItemAsetList.isLoading ? "Memuat . . ." : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Analisa Checklist Item Aset
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Aset</ThFixed>
                <Th className="p-1">Item Aset</Th>
                <Th className="p-1">Grup Aset</Th>
                <Th className="p-1">Kategori Aset</Th>
                <Th className="p-1">Jenis Aset</Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getItemAsetList?.data?.data?.length > 0 ? (
                getItemAsetList?.data?.data?.map((e, index) => (
                  <Tr key={index}>
                    <TdFixed>
                      {tableNumber({
                        pageCurrent: filter.page,
                        dataLength: filter.per_page,
                        index,
                      })}
                    </TdFixed>
                    <Td>{e.kode_item_aset ?? "-"}</Td>
                    <Td>{e.nama_item_aset ?? "-"}</Td>
                    <Td>{e.nama_grup_aset ?? "-"}</Td>
                    <Td>{e.nama_kategori_aset ?? "-"}</Td>
                    <Td>{e.nama_jenis_aset ?? "-"}</Td>
                    <TdFixed>
                      <ButtonGroup>
                        <ButtonDetail
                          icon
                          noText
                          onClick={() =>
                            history.push("item-aset/tambah/" + e.id_item_aset)
                          }
                        />
                      </ButtonGroup>
                    </TdFixed>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getItemAsetList?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getItemAsetList?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};
