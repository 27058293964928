import React, { useState, useEffect } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  BackButton,
} from 'components';
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from 'components2';
import { tableNumber } from 'utilities2';
import { ModalFilter } from './__FormCheclistComps__';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Formik } from 'formik';
import FormChecklistApi from './__FormChecklistApi__';
import { DateConvert } from 'utilities';

export const FormChecklistMaintenanceRequestList = ({ setNavbarTitle }) => {
  const history = useHistory();

  const [filter, setFilter] = useState({
    q: '',
    page: 1,
    per_page: 10,
    tgl_maintenance_request_start: undefined,
    tgl_maintenance_request_end: undefined,
    get_item: undefined,
    status_checklist: 'pending',
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);

  const [collapsedIndex, setCollapsedIndex] = useState(-1);

  const getItemAsetList = useQuery(['form_checklist', 'list', filter], () =>
    FormChecklistApi.getListMaintenanceRequest(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const onPaginationChange = ({ selected }) =>
    setFilter({ ...filter, page: selected + 1 });

  const onPaginationDataLengthChange = (e) =>
    setFilter({ ...filter, page: 1, per_page: e.target.value });

  const formInitialValuesFilter = {
    tgl_maintenance_request_start: filter?.tgl_maintenance_request_start,
    tgl_maintenance_request_end: filter?.tgl_maintenance_request_end,
    get_item: filter?.get_item,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  useEffect(() => setNavbarTitle('Form Checklist'), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="sm"
            icon
            onClick={() => history.push('/transaksi/form-checklist')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {getItemAsetList.isLoading || getItemAsetList.isError ? (
        <DataStatus
          loading={getItemAsetList.isLoading}
          text={
            getItemAsetList.isLoading ? 'Memuat . . .' : 'Data gagal dimuat'
          }
        />
      ) : (
        <>
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Maintenance Request Yang Siap Dibuatkan Work Order
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Informasi Maintenance Request</ThFixed>
                <Th className="p-1">Item Aset</Th>
                <Th className="p-1">Catatan Maintenence Request</Th>
                <ThFixed>Aksi</ThFixed>
              </Tr>
            </THead>
            <TBody>
              {getItemAsetList?.data?.data?.length > 0 ? (
                getItemAsetList?.data?.data?.map((e, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>
                        {tableNumber({
                          pageCurrent: filter.page,
                          dataLength: filter.per_page,
                          index,
                        })}
                      </TdFixed>
                      <TdFixed>
                        <div>
                          {e?.tgl_maintenance_request_master
                            ? DateConvert(
                                new Date(e?.tgl_maintenance_request_master)
                              ).defaultDMY
                            : '-'}
                        </div>
                        <div>{e?.no_maintenance_request_master ?? '-'}</div>
                      </TdFixed>
                      <Td>
                        {e.list_item_aset?.length === 0 ? (
                          '-'
                        ) : (
                          <>
                            <ul className="pl-3 m-0">
                              {e?.list_item_aset
                                ?.filter((_val, indexPermintaan) => {
                                  if (index === collapsedIndex) {
                                    return (
                                      indexPermintaan <= e.list_item_aset.length
                                    );
                                  } else {
                                    return indexPermintaan <= 2;
                                  }
                                })
                                .map((val, index) => (
                                  <li key={index}>
                                    {val.nama_item_aset ?? '-'}
                                  </li>
                                ))}
                            </ul>
                            {e?.list_item_aset?.length > 3 &&
                              index !== collapsedIndex && (
                                <Button
                                  variant="link"
                                  className="pt-0"
                                  onClick={() => setCollapsedIndex(index)}
                                >
                                  Selengkapnya...
                                </Button>
                              )}
                          </>
                        )}
                      </Td>
                      <Td>{e.catatan ?? '-'}</Td>
                      <TdFixed>
                        <ButtonGroup>
                          <ButtonCreate
                            icon
                            noText
                            onClick={() =>
                              history.push(
                                'maintenance-request/tambah/' +
                                  e.id_maintenance_request_master
                              )
                            }
                          />
                        </ButtonGroup>
                      </TdFixed>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={filter?.per_page}
            dataNumber={filter?.page * filter?.per_page - filter?.per_page + 1}
            dataPage={
              filter?.dataCount < filter?.per_page
                ? filter?.dataCount
                : filter?.page * filter?.per_page
            }
            dataCount={getItemAsetList?.data?.data_count}
            currentPage={filter?.page}
            totalPage={getItemAsetList?.data?.total_page}
            onPaginationChange={onPaginationChange}
            onDataLengthChange={onPaginationDataLengthChange}
          />
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter
            data={filter}
            setData={setFilter}
            toggle={onFilterButtonClickkHandler}
            type="preForm"
          />
        </Formik>
      )}
    </CRUDLayout>
  );
};
