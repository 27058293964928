import Services from 'services';

class PenerimaanAsetApi {
  async getList(params) {
    const fetch = await Services.get('/penerimaan_aset/page', {
      params,
    });
    return fetch.data;
  }

  async getListPurchaseOrder(params) {
    const fetch = await Services.get(
      '/penerimaan_aset/purchase_order_aset_available',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSinglePurchaseOrder(params) {
    const fetch = await Services.get(
      '/penerimaan_aset/single_purchase_order_aset_available/single',
      {
        params,
      }
    );
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get('/penerimaan_aset/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterTanah(params) {
    const fetch = await Services.get('/asettanah/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterBangunan(params) {
    const fetch = await Services.get('/asetbangunan/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterAlatMesin(params) {
    const fetch = await Services.get('/asetmesinalat/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterKendaraan(params) {
    const fetch = await Services.get('/asetkendaraan/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterInventaris(params) {
    const fetch = await Services.get('/asetinventaris/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterSistem(params) {
    const fetch = await Services.get('/asetsistemsistem/single', {
      params,
    });
    return fetch.data;
  }

  async getSingleRegisterLainnya(params) {
    const fetch = await Services.get('/asetlainnya/single', {
      params,
    });
    return fetch.data;
  }

  async getDropdownwPetugasPenerimaan(params) {
    const fetch = await Services.get('/dropdown/karyawan', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_karyawan,
      value: val.id_karyawan,
    }));
  }

  async getDropdownVendor(params) {
    const fetch = await Services.get('/dropdown/vendor', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_vendor,
      value: val.id_vendor,
    }));
  }

  async getDropdownSatuanPakai(params) {
    const fetch = await Services.get('/dropdown/satuan', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_satuan,
      value: val.id_satuan,
    }));
  }

  async getDropdownKondisiAset(params) {
    const fetch = await Services.get('/asetbangunan/dropdown', {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kondisi_aset,
      value: val.id_kondisi_aset,
    }));
  }

  async getDropdownPabrikan() {
    const fetch = await Services.get('/pabrikan/dropdown');

    return fetch?.data?.data.map((val) => ({
      label: val.nama_pabrikan,
      value: val.id_pabrikan,
    }));
  }

  async getProvinsi(params) {
    const fetch = await Services.get('/asetbangunan/dropdown', { params });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_provinsi,
      value: val.id_provinsi,
    }));
  }

  async getKabupaten(params) {
    const fetch = await Services.get('/asetbangunan/dropdown', { params });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_kabupaten,
      value: val.id_kabupaten,
    }));
  }

  async getKecamatan(params) {
    const fetch = await Services.get('/asetbangunan/dropdown', { params });
    return fetch?.data?.data.map((val) => ({
      label: val.nama_kecamatan,
      value: val.id_kecamatan,
    }));
  }

  async getDesa(params) {
    const fetch = await Services.get('/asetbangunan/dropdown', { params });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_desa,
      value: val.id_desa,
    }));
  }

  async getDropdownKeperluan() {
    return [
      {
        value: 'produksi',
        label: 'Produksi',
      },
      {
        value: 'operasional',
        label: 'Operasional',
      },
    ];
  }

  generateNumber(params) {
    return Services.get('/penerimaan_aset/no_baru', { params });
  }

  generateNumberAset(params) {
    return Services.get('/penerimaan_aset/no_baru_aset', { params });
  }

  create(data) {
    return Services.post('/penerimaan_aset', data);
  }

  createRegisterAsetTanah(data) {
    return Services.post('/penerimaan_aset/aset_tanah', data);
  }

  createRegisterAsetBangunan(data) {
    return Services.post('/penerimaan_aset/aset_bangunan', data);
  }

  createRegisterAsetKendaraan(data) {
    return Services.post('/penerimaan_aset/aset_kendaraan', data);
  }

  createRegisterAsetAlatMesin(data) {
    return Services.post('/penerimaan_aset/aset_alatmesin', data);
  }

  createRegisterAsetInventaris(data) {
    return Services.post('/penerimaan_aset/aset_inventariskantor', data);
  }

  createRegisterAsetSistem(data) {
    return Services.post('/penerimaan_aset/aset_sistemsistem', data);
  }

  createRegisterAsetLainnya(data) {
    return Services.post('/penerimaan_aset/aset_lainnya', data);
  }

  update(data) {
    return Services.put('/penerimaan_aset', data);
  }

  upload(data) {
    return Services.post('/upload/penerimaan_aset', data);
  }
}

export default new PenerimaanAsetApi();
