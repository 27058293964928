import Services from 'services';

class TransferAlatMesinWorkOrderApi {
  page(params) {
    return Services.get('/transfer_work_order_alat_mesin/page', { params });
  }

  preformPage(params) {
    return Services.get('/transfer_work_order_alat_mesin/work_order', {
      params,
    });
  }

  singlePermintaanPreform(params) {
    return Services.get('/work_order/single', {
      params,
    });
  }

  singlePermintaan(params) {
    return Services.get('/transfer_work_order_alat_mesin/permintaan', {
      params,
    });
  }

  no_baru(params) {
    return Services.get('/transfer_produksi_alat_mesin/no_baru', { params });
  }

  getDropdownAset(params) {
    return Services.get('/dropdown/item_aset', { params });
  }

  getDropdownKaryawan() {
    return Services.get('/dropdown/karyawan');
  }

  save(data) {
    return Services.post('/transfer_work_order_alat_mesin', data);
  }
}

export default new TransferAlatMesinWorkOrderApi();
