import { useContext, useState } from 'react';
import { useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import {
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  DataStatus,
  InputCurrency,
  SelectSearch,
  UpdateButton,
  DeleteButton,
  ActionButton,
} from 'components';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from 'react-icons/io5';
import { RealisasiWorkOrderContext } from '../context';

export const TabUpahSubkon = ({ buaso = 'upah', readOnly = false }) => {
  const { dataHistory } = useContext(RealisasiWorkOrderContext);
  const title = {
    upah: 'Upah',
    subkon: 'Subkon',
  };

  return (
    <>
      {/*Table List Data*/}
      <TableListData
        readOnly={readOnly}
        buaso={buaso}
        titleBuaso={title[buaso]}
      />

      {/* Table Rincian Spareparts */}
      <TableRincianSparepart
        readOnly={readOnly}
        buaso={buaso}
        titleBuaso={title[buaso]}
      />

      {!readOnly && (
        <>
          {/* History Realisasi Upah Work Order */}
          <TableHistoryRealisasiWorkOrder
            data={dataHistory?.[buaso] ?? []}
            buaso={buaso}
            titleBuaso={title[buaso]}
          />

          {/* History Realisasi Spareparts */}
          <TableHistoryRealisasiSpareparts
            data={dataHistory?.[`sparepart_${buaso}`] ?? []}
            buaso={buaso}
            titleBuaso={title[buaso]}
          />
        </>
      )}
    </>
  );
};

export const TableListData = ({ buaso, titleBuaso, readOnly }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        List Data {titleBuaso}
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th width={160}>Informasi Transfer {titleBuaso} Work Order</Th>
            <Th>Item {titleBuaso}</Th>
            <Th width={110}>Satuan</Th>
            <Th width={110}>Vendor</Th>
            {!readOnly && (
              <>
                <Th width={140}>Qty. Transfer {titleBuaso} Produksi</Th>
                <Th width={110}>Qty. Terealisasi</Th>
              </>
            )}
            <Th width={110}>Qty. Realisasi</Th>
          </Tr>
        </THead>

        <TBody>
          {values?.[buaso]?.length ? (
            values?.[buaso]?.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{index + 1}</TdFixed>
                <Td>
                  <div>
                    {val?.[`tgl_transfer_work_order_${buaso}`]
                      ? DateConvert(
                          new Date(val?.[`tgl_transfer_work_order_${buaso}`])
                        ).defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.[`no_transfer_work_order_${buaso}`]}</div>
                </Td>

                <Td>{val.nama_item ?? '-'}</Td>

                <Td>{val.nama_satuan ?? '-'}</Td>

                <Td>{val.nama_vendor ?? '-'}</Td>

                {!readOnly && (
                  <>
                    <Td textRight>
                      {parseFloat(
                        val?.[`qty_permintaan_work_order_${buaso}`] ?? 0
                      )}
                    </Td>

                    <Td textRight>{parseFloat(val?.qty_terealisasi ?? 0)}</Td>
                  </>
                )}

                <Td textRight noPadding={!readOnly}>
                  {readOnly ? (
                    `${parseFloat(val?.[`qty_realisasi_${buaso}`] ?? 0)}`
                  ) : (
                    <InputCurrency
                      noMargin
                      prefix=""
                      allowLeadingZeros={false}
                      className="text-right m-0"
                      wrapperClassName="m-0"
                      value={
                        values?.[buaso]?.[index]?.[`qty_realisasi_${buaso}`] ??
                        0
                      }
                      disabled={val.maxQty === 0}
                      onChange={(e) => {
                        console.log(e);
                        setFieldValue(
                          `${buaso}.${index}.qty_realisasi_${buaso}`,
                          e
                        );
                      }}
                      isAllowed={(e) => {
                        const { floatValue } = e;
                        return floatValue <= val.maxQty;
                      }}
                    />
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={8}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const InputRincianSparepart = ({
  index = null,
  edit = false,
  buaso,
  data,
  optionTransfer,
  setEditIndex,
}) => {
  const formikContext = useFormikContext();
  const { dropdownSparepart } = useContext(RealisasiWorkOrderContext);
  const initialValues = {
    [`id_transfer_work_order_${buaso}`]:
      data?.[`id_transfer_work_order_${buaso}`] ?? '',

    [`tgl_transfer_work_order_${buaso}`]:
      data?.[`tgl_transfer_work_order_${buaso}`] ?? '',

    [`no_transfer_work_order_${buaso}`]:
      data?.[`no_transfer_work_order_${buaso}`] ?? '',

    id_item_buaso: data?.id_item_buaso ?? '',
    nama_item: data?.nama_item ?? '',
    satuan_pakai: data?.satuan_pakai ?? '',

    [`qty_realisasi_sparepart_${buaso}`]:
      data?.[`qty_realisasi_sparepart_${buaso}`] ?? '0',

    [`harga_satuan_realisasi_sparepart_${buaso}`]:
      data?.[`harga_satuan_realisasi_sparepart_${buaso}`] ?? '0',
  };

  const validationSchema = yup.object().shape({
    [`id_transfer_work_order_${buaso}`]: yup.string().required(),
    id_item_buaso: yup.string().required(),
    [`qty_realisasi_sparepart_${buaso}`]: yup.number().required(),
    [`harga_satuan_realisasi_sparepart_${buaso}`]: yup.number().required(),
  });

  const onSubmit = (values) => {
    if (edit) {
      formikContext.setValues((prev) => ({
        ...prev,
        [`sparepart_${buaso}`]: prev?.[`sparepart_${buaso}`].toSpliced(
          index,
          1,
          values
        ),
      }));
    } else {
      formikContext.setValues((prev) => ({
        ...prev,
        [`sparepart_${buaso}`]: prev?.[`sparepart_${buaso}`].toSpliced(
          0,
          0,
          values
        ),
      }));
    }

    setEditIndex(null);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Tr>
      <Td />
      <Td noPadding>
        <SelectSearch
          noMargin
          option={optionTransfer}
          defaultValue={optionTransfer?.find(
            (item) =>
              item.value === formik.values[`id_transfer_work_order_${buaso}`]
          )}
          onChange={(e) =>
            formik.setValues((prev) => ({
              ...prev,
              ...e,
              [`id_transfer_work_order_${buaso}`]: e.value,
            }))
          }
          error={
            formik.errors?.[`id_transfer_work_order_${buaso}`] &&
            formik.touched?.[`id_transfer_work_order_${buaso}`]
          }
        />
      </Td>
      <Td noPadding>
        <SelectSearch
          noMargin
          option={dropdownSparepart?.data}
          loading={dropdownSparepart?.isLoading}
          defaultValue={dropdownSparepart?.data?.find(
            (item) => item.value === formik.values?.id_item_buaso
          )}
          onChange={(e) => {
            formik.setValues((prev) => ({
              ...prev,
              id_item_buaso: e.value,
              nama_item: e.nama_item ?? '-',
              satuan_pakai: e.satuan_pakai ?? '-',
              [`harga_satuan_realisasi_sparepart_${buaso}`]:
                parseFloat(e.hps_pakai) ?? 0,
            }));
          }}
          error={formik.errors?.id_item_buaso && formik.touched?.id_item_buaso}
        />
      </Td>
      <Td noPadding>
        <InputCurrency
          noMargin
          prefix=""
          allowLeadingZeros={false}
          className="text-right m-0"
          wrapperClassName="m-0"
          value={formik.values?.[`qty_realisasi_sparepart_${buaso}`]}
          onChange={(e) => {
            formik.setFieldValue(`qty_realisasi_sparepart_${buaso}`, e);
          }}
          error={
            formik.errors?.[`qty_realisasi_sparepart_${buaso}`] &&
            formik.touched?.[`qty_realisasi_sparepart_${buaso}`]
          }
        />
      </Td>
      <Td>{formik.values.satuan_pakai ?? '-'}</Td>
      <Td noPadding>
        <InputCurrency
          noMargin
          allowLeadingZeros={false}
          className="text-right m-0"
          wrapperClassName="m-0"
          value={formik.values?.[`harga_satuan_realisasi_sparepart_${buaso}`]}
          onChange={(e) =>
            formik.setFieldValue(`harga_satuan_realisasi_sparepart_${buaso}`, e)
          }
          error={
            formik.errors?.[`harga_satuan_realisasi_sparepart_${buaso}`] &&
            formik.touched?.[`harga_satuan_realisasi_sparepart_${buaso}`]
          }
        />
      </Td>
      <Td textRight>
        {
          RupiahConvert(
            String(
              parseFloat(
                formik.values[`harga_satuan_realisasi_sparepart_${buaso}`]
              ) * parseFloat(formik.values[`qty_realisasi_sparepart_${buaso}`])
            )
          ).getWithComa
        }
      </Td>
      <Td noPadding className="align-center">
        {edit ? (
          <ButtonGroup className="btn-block">
            <ActionButton
              size="sm"
              variant="success"
              text={<IoCheckmarkOutline />}
              onClick={formik.handleSubmit}
            />
            <ActionButton
              size="sm"
              variant="danger"
              text={<IoCloseOutline />}
              onClick={() => setEditIndex(null)}
            />
          </ButtonGroup>
        ) : (
          <ActionButton
            size="sm"
            className="btn-block"
            text={<IoAddOutline />}
            onClick={formik.handleSubmit}
          />
        )}
      </Td>
    </Tr>
  );
};

export const TableRincianSparepart = ({
  buaso,
  titleBuaso,
  readOnly = false,
}) => {
  const { values, setValues } = useFormikContext();
  const [editIndex, setEditIndex] = useState(null);

  const optionsTransferWorkOrder = values?.[buaso]?.map((val) => {
    return {
      value: val?.[`id_transfer_work_order_${buaso}`],
      label: `${val?.[`no_transfer_work_order_${buaso}`]} - ${
        val?.[`nama_item`]
      }`,
      [`tgl_transfer_work_order_${buaso}`]:
        val?.[`tgl_transfer_work_order_${buaso}`],
      [`no_transfer_work_order_${buaso}`]:
        val?.[`no_transfer_work_order_${buaso}`],
    };
  });

  const handleDelete = (index) => {
    setValues((prev) => ({
      ...prev,
      [`sparepart_${buaso}`]: prev?.[`sparepart_${buaso}`].toSpliced(index, 1),
    }));
  };

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Rincian Spareparts
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th width={300}>Informasi Transfer {titleBuaso} Work Order</Th>
            <Th width={300}>Item Spareparts</Th>
            <Th width={110}>Qty.</Th>
            <Th width={110}>Satuan</Th>
            <Th width={110}>Harga Satuan </Th>
            <Th width={110}>Jumlah</Th>
            {!readOnly && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>

        <TBody>
          {!readOnly && (
            <InputRincianSparepart
              buaso={buaso}
              optionTransfer={optionsTransferWorkOrder}
            />
          )}

          {values?.[`sparepart_${buaso}`]?.length ? (
            values?.[`sparepart_${buaso}`]?.map((val, index) => {
              if (editIndex === index) {
                return (
                  <InputRincianSparepart
                    key={index}
                    edit
                    index={index}
                    buaso={buaso}
                    data={val}
                    optionTransfer={optionsTransferWorkOrder}
                    setEditIndex={setEditIndex}
                  />
                );
              }

              return (
                <Tr key={index}>
                  <TdFixed textCenter>{index + 1}</TdFixed>

                  <Td>
                    <div>
                      {val?.[`tgl_transfer_work_order_${buaso}`]
                        ? DateConvert(
                            new Date(val?.[`tgl_transfer_work_order_${buaso}`])
                          ).defaultDMY
                        : '-'}
                    </div>
                    <div>{val?.[`no_transfer_work_order_${buaso}`]}</div>
                  </Td>

                  <Td>{val.nama_item ?? '-'}</Td>

                  <Td textRight>
                    {parseFloat(
                      val?.[`qty_realisasi_sparepart_${buaso}`] ?? '0'
                    )}
                  </Td>

                  <Td>{val.nama_satuan ?? '-'}</Td>

                  <Td textRight>
                    {
                      RupiahConvert(
                        String(
                          parseFloat(
                            val?.[
                              `harga_satuan_realisasi_sparepart_${buaso}`
                            ] ?? '0'
                          )
                        )
                      ).getWithComa
                    }
                  </Td>

                  <Td textRight>
                    {
                      RupiahConvert(
                        String(
                          parseFloat(
                            val?.[
                              `harga_satuan_realisasi_sparepart_${buaso}`
                            ] ?? '0'
                          ) *
                            parseFloat(
                              val?.[`qty_realisasi_sparepart_${buaso}`] ?? '0'
                            )
                        )
                      ).getWithComa
                    }
                  </Td>

                  {!readOnly && (
                    <Td noPadding>
                      <ButtonGroup className="btn-block">
                        <UpdateButton
                          size="sm"
                          variant="success"
                          onClick={() => setEditIndex(index)}
                        />
                        <DeleteButton
                          size="sm"
                          variant="danger"
                          onClick={() => handleDelete(index)}
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const TableHistoryRealisasiWorkOrder = ({ buaso, titleBuaso, data }) => {
  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        History Realisasi {titleBuaso} Work Order
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th width={160}>Informasi Realisasi Work Order</Th>
            <Th width={160}>Informasi Transfer {titleBuaso} Work Order</Th>
            <Th>Item {titleBuaso}</Th>
            <Th width={110}>Vendor</Th>
            <Th width={110}>Satuan</Th>
            <Th width={110}>Qty. Realisasi</Th>
          </Tr>
        </THead>

        <TBody>
          {data?.length ? (
            data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{index + 1}</TdFixed>

                <Td>
                  <div>
                    {val.tgl_realisasi_work_order
                      ? DateConvert(new Date(val.tgl_realisasi_work_order))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_realisasi_work_order}</div>
                </Td>

                <Td>
                  <div>
                    {val?.[`tgl_transfer_work_order_${buaso}`]
                      ? DateConvert(
                          new Date(val?.[`tgl_transfer_work_order_${buaso}`])
                        ).defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.[`no_transfer_work_order_${buaso}`]}</div>
                </Td>

                <Td>{val.nama_item ?? '-'}</Td>

                <Td>{val.nama_vendor ?? '-'}</Td>

                <Td>{val.nama_satuan ?? '-'}</Td>

                <Td textRight>
                  {parseFloat(val?.[`qty_realisasi_${buaso}`] ?? 0)}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const TableHistoryRealisasiSpareparts = ({
  buaso,
  titleBuaso,
  data,
}) => {
  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        History Realisasi Spareparts
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th width={160}>Informasi Realisasi Work Order</Th>
            <Th width={160}>Informasi Transfer {titleBuaso} Work Order</Th>
            <Th>Item Spareparts</Th>
            <Th width={110}>Qty.</Th>
            <Th width={110}>Satuan</Th>
            <Th width={110}>Harga Satuan </Th>
            <Th width={110}>Jumlah</Th>
          </Tr>
        </THead>

        <TBody>
          {data?.length ? (
            data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{index + 1}</TdFixed>

                <Td>
                  <div>
                    {val?.tgl_realisasi_work_order
                      ? DateConvert(new Date(val?.tgl_realisasi_work_order))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.no_realisasi_work_order}</div>
                </Td>

                <Td>
                  <div>
                    {val?.[`tgl_transfer_work_order_${buaso}`]
                      ? DateConvert(
                          new Date(val?.[`tgl_transfer_work_order_${buaso}`])
                        ).defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.[`no_transfer_work_order_${buaso}`]}</div>
                </Td>

                <Td>{val.nama_item ?? '-'}</Td>

                <Td textRight>
                  {
                    DecimalConvert(
                      val?.[`qty_realisasi_sparepart_${buaso}`] ?? 0
                    ).getWithComa
                  }
                </Td>

                <Td>{val.nama_satuan ?? '-'}</Td>

                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(
                          val?.[`harga_satuan_realisasi_sparepart_${buaso}`]
                        )
                      )
                    ).getWithComa
                  }
                </Td>

                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(
                          val?.[`harga_satuan_realisasi_sparepart_${buaso}`]
                        ) *
                          parseFloat(val?.[`qty_realisasi_sparepart_${buaso}`])
                      )
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={9}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};
