import Services from 'services';

class CloseWorkOrderApi {
  page(params) {
    return Services.get('/close_work_order/page', { params });
  }

  single(params) {
    return Services.get('/close_work_order/single', { params });
  }

  preform(params) {
    return Services.get('/close_work_order/work_order_available?', { params });
  }

  getSingleWorkOrder(params) {
    return Services.get('/work_order/single', { params });
  }

  getNomor(params) {
    return Services.get('/close_work_order/no_baru', { params });
  }

  getDropdownAset() {
    return Services.get('/analisa_item_checklist/item_aset');
  }

  save(data) {
    return Services.post('/close_work_order', data);
  }
}

export default new CloseWorkOrderApi();
