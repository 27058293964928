import { useFormikContext } from 'formik';
import {
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  DataStatus,
  InputCurrency,
} from 'components';
import { DateConvert } from 'utilities';
import { useContext } from 'react';
import { RealisasiWorkOrderContext } from '../context';

export const TabAlatMesinOverhead = ({
  buaso = 'alat_mesin',
  readOnly = false,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { dataHistory } = useContext(RealisasiWorkOrderContext);

  const title = {
    alat_mesin: 'Alat Mesin',
    overhead: 'Overhead',
  };

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        List Data {title[buaso]}
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th width={160}>Informasi Transfer {title[buaso]} Work Order</Th>
            <Th>Item {title[buaso]}</Th>
            <Th width={110}>Satuan</Th>
            {!readOnly && (
              <>
                <Th width={140}>Qty. Transfer {title[buaso]} Produksi</Th>
                <Th width={110}>Qty. Terealisasi</Th>
              </>
            )}
            <Th width={110}>Qty. Realisasi</Th>
          </Tr>
        </THead>

        <TBody>
          {values?.[buaso]?.length ? (
            values?.[buaso]?.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{index + 1}</TdFixed>

                <Td>
                  <div>
                    {val?.[`tgl_transfer_work_order_${buaso}`]
                      ? DateConvert(
                          new Date(val?.[`tgl_transfer_work_order_${buaso}`])
                        ).defaultDMY
                      : '-'}
                  </div>
                  <div>{val?.[`no_transfer_work_order_${buaso}`]}</div>
                </Td>

                <Td>{val.nama_item ?? val.nama_item_aset ?? '-'}</Td>

                <Td>{val.nama_satuan ?? '-'}</Td>

                {!readOnly && (
                  <>
                    <Td textRight>
                      {parseFloat(
                        val?.[`qty_permintaan_work_order_${buaso}`] ?? 0
                      )}
                    </Td>

                    <Td textRight>{parseFloat(val?.qty_terealisasi ?? 0)}</Td>
                  </>
                )}

                <Td textRight noPadding={!readOnly}>
                  {readOnly ? (
                    `${parseFloat(val?.[`qty_realisasi_${buaso}`] ?? 0)}`
                  ) : (
                    <InputCurrency
                      noMargin
                      prefix=""
                      allowLeadingZeros={false}
                      className="text-right m-0"
                      wrapperClassName="m-0"
                      disabled={val.maxQty === 0}
                      value={
                        values?.[buaso]?.[index]?.[`qty_realisasi_${buaso}`] ??
                        0
                      }
                      onChange={(e) =>
                        setFieldValue(
                          `${buaso}.${index}.qty_realisasi_${buaso}`,
                          e
                        )
                      }
                    />
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>

      {!readOnly && (
        <>
          <div className="font-weight-bold" style={{ fontSize: '14px' }}>
            History Realisasi {title[buaso]} Work Order
          </div>

          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <Th width={160}>Informasi Realisasi Work Order</Th>
                <Th width={160}>
                  Informasi Transfer {title[buaso]} Work Order
                </Th>
                <Th>Item {title[buaso]}</Th>
                <Th width={110}>Satuan</Th>
                <Th width={110}>Qty. Realisasi</Th>
              </Tr>
            </THead>

            <TBody>
              {dataHistory?.[buaso]?.length ? (
                dataHistory?.[buaso]?.map((val, index) => (
                  <Tr key={index}>
                    <TdFixed textCenter>{index + 1}</TdFixed>

                    <Td>
                      <div>
                        {val.tgl_realisasi_work_order
                          ? DateConvert(new Date(val.tgl_realisasi_work_order))
                              .defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_realisasi_work_order}</div>
                    </Td>

                    <Td>
                      <div>
                        {val?.[`tgl_transfer_work_order_${buaso}`]
                          ? DateConvert(
                              new Date(
                                val?.[`tgl_transfer_work_order_${buaso}`]
                              )
                            ).defaultDMY
                          : '-'}
                      </div>
                      <div>{val?.[`no_transfer_work_order_${buaso}`]}</div>
                    </Td>

                    <Td>{val.nama_item_aset ?? val.nama_item ?? '-'}</Td>

                    <Td>{val.nama_satuan ?? '-'}</Td>

                    <Td textRight>
                      {parseFloat(val?.[`qty_realisasi_${buaso}`] ?? 0)}
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={9}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
        </>
      )}
    </>
  );
};
