import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import {
  Input,
  InfoItemVertical,
  Select,
  TextArea,
  ButtonBack,
  ButtonCreate,
} from "components2";

import MaintenanceRequestApi from "../__MaintenanceRequestApi__";
import { DatePicker } from "components";
import { TableAnalisaChecklistContent } from "./TableAnalisaChecklistContent";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ModalForm } from "./ModalForm";
import * as Yup from "yup";
import { DateConvert } from "utilities";

const InfoMaintenanceRequest = () => {
  const { values } = useFormikContext();
  return (
    <>
      <Row>
        <Col>
          <InfoItemVertical
            label="Tgl. Maintenance Request"
            text={
              values?.tgl_maintenance_request_master
                ? DateConvert(new Date(values?.tgl_maintenance_request_master))
                    .detail
                : "-"
            }
          />
        </Col>
        <Col>
          <InfoItemVertical
            label="No. Maintenance Request"
            text={values?.no_maintenance_request_master ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};

export const FormAnalisaChecklistContent = ({ action }) => {
  const { values, handleSubmit, handleChange, setValues, errors, touched } =
    useFormikContext();
  const history = useHistory();

  const [modalConfig, setModalConfig] = useState({
    show: false,
    data: {},
    type: "",
  });

  const { isFetching: loadingKode } = useQuery(
    ["maintenanceRequest", "kode", values?.tgl_maintenance_request_master],
    () =>
      MaintenanceRequestApi.generateNumber({
        tanggal: values?.tgl_maintenance_request_master,
      }).then((val) =>
        setValues({ ...values, no_maintenance_request_master: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_maintenance_request_master && action === "create",
    }
  );

  const modalInitialValues = {
    idGrupAset: undefined,
    namaGrupAset: "",
    idKelompokAset: undefined,
    namaKelompokAset: "",
    idJenisAset: undefined,
    namaJenisAset: "",
    idItemAset: undefined,
    namaItemAset: "",
    kodeItemAset: "",
    keterangan: "",
  };

  const modalValidationSchema = Yup.object().shape({
    idGrupAset: Yup.string().required("Pilih grup aset"),
    idKelompokAset: Yup.string().required("Pilih kelompok aset"),
    idJenisAset: Yup.string().required("Pilih jenis aset"),
    idItemAset: Yup.string().required("Pilih item aset"),
  });

  const modalSubmitHandler = (value, { setSubmitting, resetForm }) => {
    setValues({ ...values, detail: [...values?.detail, value] });
    setSubmitting(false);
    resetForm();
    setModalConfig({ show: false });
  };

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === "create"
            ? "Tambah Data Maintenance Request"
            : action === "update"
            ? "Ubah Data Maintenance Request"
            : "Detail Data Maintenance Request"}
        </small>

        <ButtonBack
          size="sm"
          onClick={() => history.push("/transaksi/maintenance-request")}
        />
      </div>
      <Card>
        <Card.Body>
          {action === "detail" ? (
            <InfoMaintenanceRequest />
          ) : (
            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Maintenance Request"
                  selected={
                    values?.tgl_maintenance_request_master
                      ? new Date(values?.tgl_maintenance_request_master)
                      : null
                  }
                  onChange={(val) =>
                    setValues({
                      ...values,
                      tgl_maintenance_request_master: DateConvert(new Date(val))
                        .default,
                    })
                  }
                  error={
                    errors.tgl_maintenance_request_master &&
                    touched.tgl_maintenance_request_master &&
                    true
                  }
                  errorText={
                    errors.tgl_maintenance_request_master &&
                    touched.tgl_maintenance_request_master &&
                    errors.tgl_maintenance_request_master
                  }
                />
              </Col>
              <Col>
                <Input
                  label="No. Maintenance Request"
                  disabled
                  placeholder="Pilih tanggal terlebih dahulu!!!"
                  loading={loadingKode}
                  value={values?.no_maintenance_request_master}
                />
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between my-3">
        <small className="font-weight-bold mt-2">
          List Item Aset Yang Memerlukan Pemeliharaan
        </small>
        {action !== "detail" && (
          <ButtonCreate
            text="Tambah Item Aset"
            variant="primary"
            onClick={() => {
              setModalConfig({ ...modalConfig, show: true });
            }}
          />
        )}
      </div>
      <Card>
        <Card.Body>
          <TableAnalisaChecklistContent
            dataTable={values?.detail}
            action={action}
          />
        </Card.Body>
      </Card>

      <div className="mt-3">
        <TextArea
          label="Catatan"
          rows="4"
          name="catatan"
          onChange={handleChange}
          value={values?.catatan}
          disabled={action === "detail"}
        />
      </div>

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Simpan"
            variant={action === "update" ? "success" : "primary"}
            onClick={handleSubmit}
          />
        </div>
      )}

      {modalConfig.show && (
        <Formik
          initialValues={modalInitialValues}
          validationSchema={modalValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          <ModalForm
            setModalConfig={setModalConfig}
            modalConfig={modalConfig}
          />
        </Formik>
      )}
    </Fragment>
  );
};
