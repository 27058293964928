import { useContext, useEffect, useRef, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  InfoItemHorizontal,
} from 'components';
import { DateConvert } from 'utilities';
import { CloseWorkOrderApi } from 'api';
import {
  AlertCloseWorkOrder,
  FormCloseProduksi,
  InfoMaintenanceRequest,
  TableRealisasiPekerjaan,
  TabRealisasiBiaya,
} from './components';
import { CloseWorkOrderContext, closeWorkOrderProvider } from './context';
import { ModalCreateWorkOrder } from './components';

export const TambahCloseWorkOrder = closeWorkOrderProvider(
  ({ setNavbarTitle }) => {
    const formikRef = useRef();
    const title = 'Close Work Order';
    const location = useLocation();
    const { id_work_order } = useParams();
    const history = useHistory();

    const { modalWorkOrder, setModalWorkOrder } = useContext(
      CloseWorkOrderContext
    );

    const [infoMaintenance, setInfoMaintenance] = useState({});
    const [data, setData] = useState({});
    const [page, setPage] = useState({
      loading: true,
      status: false,
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });

    const getData = () => {
      setPage({
        loading: true,
        status: false,
      });

      CloseWorkOrderApi.single({
        id_work_order,
      })
        .then((res) => {
          const {
            tgl_maintenance_request_master,
            no_maintenance_request_master,
            catatan_maintenance_request,
            ...data
          } = res.data.data;

          setData({
            ...data,
            posting_bahan: data?.posting_bahan?.map((bahan) => ({
              ...bahan,
              jumlah:
                parseFloat(bahan.jumlah) *
                parseFloat(bahan.qty_realisasi_bahan),
            })),
            posting_alat_mesin: data?.posting_alat_mesin?.map((alat) => ({
              ...alat,
              jumlah:
                parseFloat(alat.jumlah) *
                parseFloat(alat.qty_realisasi_alat_mesin),
            })),
            posting_overhead: data?.posting_overhead?.map((ovh) => ({
              ...ovh,
              jumlah:
                parseFloat(ovh.jumlah) * parseFloat(ovh.qty_realisasi_overhead),
            })),
          });
          setInfoMaintenance({
            tgl_maintenance_request_master,
            no_maintenance_request_master,
            catatan_maintenance_request,
          });
          setPage({
            loading: false,
            status: true,
          });
        })
        .catch(() => {
          setPage({
            loading: false,
            status: false,
          });
        });
    };

    const onSubmit = (values, { setSubmitting }) => {
      const dataSubmit = {
        id_work_order: id_work_order,
        status_work_order: 'close',
        tgl_close_work_order: DateConvert(values?.tgl_close_work_order).default,
      };

      CloseWorkOrderApi.save(dataSubmit)
        .then(() => {
          setModalWorkOrder({ show: false, data: null });
          history.push('/transaksi/close-work-order', {
            alert: {
              show: true,
              text: 'Data berhasil disimpan!',
              variant: 'primary',
            },
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal di simpan!',
          });
          setSubmitting(false);
        });
    };

    const onCloseDetail = () => {
      setModalWorkOrder({ show: false, data: null });
    };

    useEffect(() => {
      setNavbarTitle(title);
      getData();

      // eslint-disable-next-line
    }, []);

    return (
      <>
        <div className="text-right">
          <BackButton
            onClick={() =>
              history.replace(
                '/transaksi/close-work-order/list-work-order/',
                location.state
              )
            }
          />
        </div>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => {
            setAlertConfig({
              ...alertConfig,
              show: false,
            });
          }}
        />

        {page.loading || !page.status ? (
          <DataStatus
            loading={page.loading}
            text={
              page.loading
                ? 'Memuat data...'
                : 'Data gagal dimuat, tidak dapat menampilkan data'
            }
          />
        ) : (
          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
              ...data,
              tgl_close_work_order: '',
            }}
            validationSchema={yup.object().shape({
              tgl_close_work_order: yup
                .string()
                .required('Tgl. Close Work Order diperlukan!'),
            })}
            onSubmit={onSubmit}
          >
            {({ validateForm, setFieldTouched }) => {
              return (
                <>
                  <div
                    className="font-weight-bold "
                    style={{ fontSize: '14px' }}
                  >
                    Detail Data Work Order
                  </div>
                  <Card className="mb-3">
                    <Card.Body>
                      <Row>
                        <Col>
                          <InfoItemHorizontal
                            label="Tgl. Work Order"
                            text={
                              data?.tgl_work_order
                                ? DateConvert(new Date(data?.tgl_work_order))
                                    .detail
                                : '-'
                            }
                          />

                          <InfoItemHorizontal
                            label="No. Work Order"
                            text={data.no_work_order}
                          />

                          <InfoItemHorizontal
                            label="Item Aset"
                            text={data.nama_item_aset}
                          />

                          <InfoItemHorizontal
                            label="Petugas Maintenance"
                            text={data.nama_petugas ?? '-'}
                          />

                          <InfoItemHorizontal
                            label="Status Petugas Maintenance"
                            text={
                              data?.is_mekanik_eksternal
                                ? 'Mekanik Eksternal'
                                : 'Mekanik Internal'
                            }
                          />

                          <InfoItemHorizontal
                            label="Vendor"
                            text={data.nama_vendor ?? 'AJB'}
                          />
                        </Col>

                        <Col>
                          <InfoMaintenanceRequest data={infoMaintenance} />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>

                  <FormCloseProduksi />

                  <TableRealisasiPekerjaan />

                  <TabRealisasiBiaya />

                  <AlertCloseWorkOrder />

                  <div className="text-right mt-4">
                    <ActionButton
                      text="Close Work Order"
                      variant="danger"
                      onClick={async () => {
                        await validateForm().then((res) => {
                          const hasErros = Object.keys(res);

                          if (hasErros.length > 0) {
                            hasErros.forEach((item) => {
                              setFieldTouched(item, true);
                            });
                          } else {
                            setModalWorkOrder({
                              show: true,
                              data: data,
                            });
                          }
                        });
                      }}
                    />
                  </div>

                  <ModalCreateWorkOrder
                    show={modalWorkOrder.show}
                    data={data}
                    infoMaintenance={infoMaintenance}
                    onClose={onCloseDetail}
                  />
                </>
              );
            }}
          </Formik>
        )}
      </>
    );
  }
);
