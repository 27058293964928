export const formInitialValues = (data) => {
  return Object({
    id_permintaan_aset: data?.id_permintaan_aset ?? undefined,
    tgl_permintaan_aset: data?.tgl_permintaan_aset ?? undefined,
    no_permintaan_aset: data?.no_permintaan_aset ?? undefined,
    catatan_permintaan: data?.catatan_permintaan ?? '',
    stakeholder: data?.stakeholder ?? [],
    detail: data?.detail
      ? data.detail.map((val) => ({
          id_permintaan_aset_detail:
            val?.id_permintaan_aset_detail ?? undefined,
          idEntitasAset: val?.id_entitas_aset ?? undefined,
          namaEntitasAset: val?.nama_entitas_aset ?? '',
          idGrupAset: val?.id_grup_aset ?? undefined,
          namaGrupAset: val?.nama_grup_aset ?? '',
          idKategoriAset: val?.id_kategori_aset ?? undefined,
          namaKategoriAset: val?.nama_kategori_aset ?? '',
          idJenisAset: val?.id_jenis_aset ?? undefined,
          namaJenisAset: val?.nama_jenis_aset ?? '',
          idUnitProduksi: val?.id_unit_organisasi ?? undefined,
          namaUnitProduksi: val?.nama_unit_organisasi ?? '',
          keperluan: val?.keperluan ?? undefined,
          keperluanLabel: val?.keperluan ?? '',
          metodePengadaan: val?.metode_pengadaan ?? undefined,
          metodePengadaanLabel: val?.metode_pengadaan ?? '',
          namaAset: val?.nama_aset ?? '',
          keterangan: val?.keterangan ?? '',
          file:
            val?.file?.length > 0
              ? val?.file?.map((val) => val?.path_file)
              : [],
        }))
      : [],
  });
};
