import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { FormPermintaanAsetContent } from './__PermintaanAsetComps__';
import { useQuery } from 'react-query';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import MaintenanceRequestApi from './__PenerimaanAsetApi__';
import { formInitialValues } from './__PermintaanAsetUtils__';
import { Alert, DataStatus } from 'components';

export const PenerimaanAsetDetail = ({ setNavbarTitle }) => {
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: '',
    variant: 'primary',
  });

  const {
    data: detailPenerimaanAset,
    isFetching: loadingDetailPenerimaanAset,
  } = useQuery(
    ['penerimaanAset', 'single', id],
    () =>
      MaintenanceRequestApi.getSingle({
        id_penerimaan_aset: id,
      }),
    { enabled: !!id }
  );

  useEffect(() => {
    setNavbarTitle('Penerimaan Aset');

    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailPenerimaanAset ? (
        <DataStatus loading text="Memuat Data..." />
      ) : (
        <Formik initialValues={formInitialValues(detailPenerimaanAset?.data)}>
          <FormPermintaanAsetContent action="detail" />
        </Formik>
      )}
    </>
  );
};
