import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Alert,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  CreateButton,
} from 'components';
import { WorkOrderApi } from 'api';
import { DateConvert, PageNumber } from 'utilities';
import { ModalDetailWorkOrder, ModalFilterPage } from './components';
import { workOrderProvider } from './context/';

export const ListWorkOrder = workOrderProvider(({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const title = 'Work Order';
  const filter = location.state?.filter ?? {};

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState('');
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: filter.page?.active,
    page: filter.page?.page ?? 1,
    per_page: filter.page?.per_page ?? 10,
    q: filter.page?.q ?? '',
    tgl_work_order_start: filter.page?.tgl_work_order_start,
    tgl_work_order_end: filter.page?.tgl_work_order_end,
    tgl_maintenance_request_start: filter.page?.tgl_maintenance_request_start,
    tgl_maintenance_request_end: filter.page?.tgl_maintenance_request_end,
    id_item_aset: filter.page?.id_item_aset,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [collapsedIndex, setCollapsedIndex] = useState(-1);
  const [modalWorkOrder, setModalWorkOrder] = useState({
    show: false,
    data: null,
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    const filterData = { ...dataFilter };
    delete filterData.active;

    WorkOrderApi.page(filterData)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setDataFilter({
      ...dataFilter,
      page: 1,
      q: key,
    });
    setAlertConfig({
      show: key ? true : false,
      variant: 'primary',
      text: 'Hasil dari pencarian: ' + key,
    });
  };

  const onCloseDetail = () => {
    setModalWorkOrder({ show: false, data: null });
  };

  useEffect(() => {
    setNavbarTitle(title);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCollapsedIndex(-1);
    getData();

    // eslint-disable-next-line
  }, [dataFilter]);

  const toggleModalFilter = () => setModalFilter(!modalFilter);

  const Table = () => {
    const STATUS_WORK_ORDER = {
      open: {
        variant: 'outline-warning',
        textColor: 'text-warning',
        label: 'ON PROGRESS',
      },
      close: {
        variant: 'outline-secondary',
        textColor: 'text-secondary',
        label: 'CLOSED',
      },
    };

    return (
      <>
        <b>List Data Work Order</b>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Work Order</ThFixed>
              <ThFixed>Informasi Maintenance Request</ThFixed>
              <Th>Item Aset</Th>
              <Th>Rincian Pekerjaan</Th>
              <ThFixed>Status Work Order</ThFixed>
            </Tr>
          </THead>

          <TBody>
            {data.map((val, index) => {
              const status = STATUS_WORK_ORDER[val.status_work_order ?? 'open'];

              return (
                <Tr key={index}>
                  <TdFixed textCenter>
                    {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                  </TdFixed>

                  <TdFixed>
                    <ReadButton
                      noMargin
                      size="sm"
                      className="my-1"
                      onClick={() => {
                        setModalWorkOrder({
                          show: true,
                          data: val,
                        });
                      }}
                    />
                  </TdFixed>

                  <Td>
                    <div>
                      {val.tgl_work_order
                        ? DateConvert(new Date(val.tgl_work_order)).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_work_order ?? '-'}</div>
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_maintenance_request_master
                        ? DateConvert(
                            new Date(val.tgl_maintenance_request_master)
                          ).defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_maintenance_request_master ?? '-'}</div>
                  </Td>

                  <Td>{val.nama_item_aset ?? '-'}</Td>

                  <Td>
                    {val.rincian_pekerjaan?.length === 0 ? (
                      '-'
                    ) : (
                      <>
                        <ul className="pl-3 m-0">
                          {val?.rincian_pekerjaan
                            ?.filter((_val, indexPermintaan) => {
                              if (index === collapsedIndex) {
                                return (
                                  indexPermintaan <=
                                  val?.rincian_pekerjaan?.length
                                );
                              } else {
                                return indexPermintaan <= 2;
                              }
                            })
                            .map((val, index) => (
                              <li key={index}>{val.nama_pekerjaan ?? '-'}</li>
                            ))}
                        </ul>
                        {val?.rincian_pekerjaan?.length > 3 &&
                          index !== collapsedIndex && (
                            <Button
                              variant="link"
                              className="pt-0"
                              onClick={() => setCollapsedIndex(index)}
                            >
                              Selengkapnya...
                            </Button>
                          )}
                      </>
                    )}
                  </Td>

                  <TdFixed>
                    <Button
                      variant={status.variant}
                      textColor={status.textColor}
                      className="text-nowrap btn-block"
                    >
                      {status.label}
                    </Button>
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={dataFilter.per_page}
          dataPage={
            totalData <= 10
              ? data.length
              : data.map((_res, index) => {
                  if (index === data.length - 1) {
                    return PageNumber(
                      dataFilter.page,
                      dataFilter.per_page,
                      index
                    );
                  } else {
                    return null;
                  }
                })
          }
          dataNumber={data.map((_res, index) => {
            if (index === 0) {
              return PageNumber(dataFilter.page, dataFilter.per_page, index);
            } else {
              return null;
            }
          })}
          dataCount={totalData}
          onDataLengthChange={(e) => {
            setDataFilter({
              ...dataFilter,
              per_page: e.target.value,
              page: 1,
            });
          }}
          currentPage={dataFilter.page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={dataFilter?.q} onChange={onInputSearchChange} />

            <FilterButton
              active={dataFilter?.active}
              onClick={toggleModalFilter}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => {
              history.push('/transaksi/work-order/list-maintenance-request', {
                filter: {
                  ...filter,
                  page: dataFilter,
                },
              });
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => {
          setAlertConfig({
            ...alertConfig,
            show: false,
          });
        }}
      />

      {isLoading ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalDetailWorkOrder
        show={modalWorkOrder.show}
        data={modalWorkOrder.data}
        onClose={onCloseDetail}
      />

      {modalFilter && (
        <ModalFilterPage
          toggle={toggleModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}
    </CRUDLayout>
  );
});
