import { useState, useContext } from 'react';
import { Nav, Tab, Card, ButtonGroup } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import {
  Table,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  Td,
  TdFixed,
  DataStatus,
  UpdateButton,
  DeleteButton,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { CloseWorkOrderContext } from '../context';

export const TabRealisasiBiaya = ({ createable }) => {
  const {
    dropdownBahan,
    dropdownUpah,
    dropdownAlat,
    dropdownSubkon,
    dropdownOverhead,
  } = useContext(CloseWorkOrderContext);

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Realisasi Biaya
      </div>

      <Card className="mb-3">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header>
            <Nav variant="tabs" defaultActiveKey="bahan">
              <NavTab eventKey="bahan" title="Bahan" />
              <NavTab eventKey="upah" title="Upah" />
              <NavTab eventKey="alat" title="Alat Mesin" />
              <NavTab eventKey="subkon" title="Subkon" />
              <NavTab eventKey="overhead" title="Overhead" />
            </Nav>
          </Card.Header>

          <Card.Body className="p-3">
            <Tab.Content>
              <TabPane
                eventKey="bahan"
                component={
                  <TableRealisasiBiaya
                    buaso="bahan"
                    createable={createable}
                    dropdown={dropdownBahan}
                  />
                }
              />
              <TabPane
                eventKey="upah"
                component={
                  <TableRealisasiBiaya
                    buaso="upah"
                    createable={createable}
                    dropdown={dropdownUpah}
                  />
                }
              />
              <TabPane
                eventKey="alat"
                component={
                  <TableRealisasiBiaya
                    buaso="alat_mesin"
                    createable={createable}
                    dropdown={dropdownAlat}
                  />
                }
              />
              <TabPane
                eventKey="subkon"
                component={
                  <TableRealisasiBiaya
                    buaso="subkon"
                    createable={createable}
                    dropdown={dropdownSubkon}
                  />
                }
              />
              <TabPane
                eventKey="overhead"
                component={
                  <TableRealisasiBiaya
                    buaso="overhead"
                    createable={createable}
                    dropdown={dropdownOverhead}
                  />
                }
              />
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export const TableRealisasiBiaya = ({ buaso }) => {
  const { values, setValues } = useFormikContext();

  const title = {
    bahan: 'Bahan',
    upah: 'Upah',
    alat_mesin: 'Alat Mesin',
    subkon: 'Subkon',
    overhead: 'Overhead',
  };

  const isUpahSubkon = buaso === 'upah' || buaso === 'subkon';

  return (
    <>
      <div className="font-weight-bold" style={{ fontSize: '14px' }}>
        Posting Realisasi {title[buaso]} Work Order
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Informasi Posting Realisasi Work Order</Th>
            <Th>Item {title[buaso]}</Th>
            {isUpahSubkon && <Th>Vendor</Th>}
            <Th>Jumlah</Th>
            <Th>Akun COA</Th>
            <Th>Keterangan Jurnal</Th>
          </Tr>
        </THead>

        <TBody>
          {values?.[`posting_${buaso}`]?.length > 0 ? (
            values?.[`posting_${buaso}`]?.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed textCenter>{index + 1}</TdFixed>
                  <Td>
                    <div>
                      {val?.[`tgl_posting_realisasi_work_order_${buaso}`]
                        ? DateConvert(
                            new Date(
                              val?.[`tgl_posting_realisasi_work_order_${buaso}`]
                            )
                          ).defaultDMY
                        : '-'}
                    </div>
                    <div>
                      {val?.[`no_posting_realisasi_work_order_${buaso}`]}
                    </div>
                  </Td>
                  <Td>{val?.nama_item ?? val.nama_item_aset ?? '-'}</Td>
                  {isUpahSubkon && <Td>{val?.nama_vendor ?? '-'}</Td>}

                  {console.log(val)}

                  <Td textRight>
                    {
                      RupiahConvert(
                        String(Math.round(parseFloat(val.jumlah ?? 0)))
                      ).getWithComa
                    }
                  </Td>
                  <Td>
                    <div>
                      {`${val.nomor_akun_debet} - ${val.nama_akun_debet}`}
                    </div>
                    <div>
                      {`${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}
                    </div>
                  </Td>
                  <Td>{val.keterangan_jurnal}</Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);
