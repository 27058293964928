export const formInitialValues = (data) => {
  const base_url = process.env.REACT_APP_EXPORT_FILE_BASE_URL;
  return Object({
    id_penerimaan_aset: data?.id_penerimaan_aset ?? undefined,
    id_aset_yang_diterima: data?.id_aset_yang_diterima ?? undefined,
    id_purchase_order_aset_detail:
      data?.id_purchase_order_aset_detail ?? undefined,
    id_petugas_penerimaan: data?.id_petugas_penerimaan ?? undefined,
    tgl_penerimaan_aset: data?.tgl_penerimaan_aset ?? undefined,
    no_penerimaan_aset: data?.no_penerimaan_aset ?? undefined,
    no_surat_jalan_vendor: data?.no_surat_jalan_vendor ?? undefined,
    nama_petugas_penerimaan: data?.nama_petugas_penerimaan ?? undefined,
    tgl_purchase_order_aset: data?.tgl_purchase_order_aset ?? undefined,
    no_purchase_order_aset: data?.no_purchase_order_aset ?? '',
    tgl_seleksi_vendor_aset: data?.tgl_seleksi_vendor_aset ?? undefined,
    no_seleksi_vendor_aset: data?.no_seleksi_vendor_aset ?? '',
    nama_grup_aset: data?.nama_grup_aset ?? '',
    nama_jenis_aset: data?.nama_jenis_aset ?? '',
    nama_kategori_aset: data?.nama_kategori_aset ?? '',
    nama_aset: data?.nama_aset ?? '',
    keperluan: data?.keperluan ?? '',
    file:
      data?.file?.length > 0 ? data?.file?.map((val) => val?.path_file) : [],
    bukti_penerimaan: data?.bukti_penerimaan
      ? base_url + data?.bukti_penerimaan
      : undefined,
  });
};

export const formInitialValuesRegisterAset = (data) => {
  return Object({
    tgl_penerimaan_aset: data?.tgl_penerimaan_aset ?? undefined,
    no_penerimaan_aset: data?.no_penerimaan_aset ?? '',
    tgl_purchase_order_aset: data?.tgl_purchase_order_aset ?? undefined,
    no_purchase_order_aset: data?.no_purchase_order_aset ?? '',
    keperluan: data?.keperluan ?? '',
    nama_entitas_aset: data?.nama_entitas_aset ?? '',
    nama_grup_aset: data?.nama_grup_aset ?? '',
    nama_kategori_aset: data?.nama_kategori_aset ?? '',
    nama_jenis_aset: data?.nama_jenis_aset ?? '',
    nama_unit_organisasi: data?.nama_unit_organisasi ?? '',
    id_entitas_aset: data?.id_entitas_aset ?? undefined,
    id_grup_aset: data?.id_grup_aset ?? undefined,
    id_kategori_aset: data?.id_kategori_aset ?? undefined,
    id_jenis_aset: data?.id_jenis_aset ?? undefined,
    id_unit_organisasi: data?.id_unit_organisasi ?? undefined,
    nama_item_aset: data?.nama_item_aset ?? '',
    harga_perolehan: data?.harga_perolehan ?? 0,
    id_penerimaan_aset: data?.id_penerimaan_aset ?? undefined,
    id_kondisi_aset: data?.id_kondisi_aset ?? undefined,
    nama_kondisi_aset: data?.nama_kondisi_aset ?? '',
    id_karyawan: data?.id_karyawan ?? undefined,
    nama_karyawan: data?.nama_karyawan ?? '',
    id_provinsi: data?.id_provinsi ?? undefined,
    nama_provinsi: data?.nama_provinsi ?? '',
    id_kabupaten: data?.id_kabupaten ?? undefined,
    nama_kabupaten: data?.nama_kabupaten ?? '',
    id_kecamatan: data?.id_kecamatan ?? undefined,
    nama_kecamatan: data?.nama_kecamatan ?? '',
    id_desa: data?.id_desa ?? undefined,
    nama_desa: data?.nama_desa ?? '',
    detail_lokasi: data?.detail_lokasi ?? '',
    kode_item_aset: data?.kode_item_aset ?? '',
    bulan_perolehan: data?.bulan_perolehan ?? undefined,
    tahun_perolehan: data?.tahun_perolehan ?? undefined,
    id_satuan_pakai: data?.id_satuan_pakai ?? undefined,
    nama_satuan_pakai: data?.nama_satuan_pakai ?? '',
    id_pabrikan: data?.id_pabrikan ?? undefined,
    nama_pabrikan: data?.nama_pabrikan ?? '',
    no_sertifikat: data?.no_sertifikat ?? '',
    plat_nomor: data?.plat_nomor ?? '',
    no_seri: data?.no_seri ?? '',
    disewa: data?.disewa ?? false,
    produksi: data?.produksi ?? false,
    keterangan: data?.keterangan,
  });
};
