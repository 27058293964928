import {
  Checkbox,
  DataStatus,
  Input,
  SelectSearch,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from 'components';
import { useFormikContext } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { IoCheckmark, IoClose } from 'react-icons/io5';

export const TableChecklist = ({ type, data, action }) => {
  const { setValues, handleChange, errors, touched, values } =
    useFormikContext();

  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <ThFixed rowSpan="2">No.</ThFixed>
            <Th rowSpan="2" className="text-capitalize">
              {type}
            </Th>
            <Th colSpan="2">Status</Th>
            <Th colSpan="3">Kondisi</Th>
            <Th rowSpan="2">Keterangan</Th>
          </Tr>
          <Tr>
            <Th>Ada</Th>
            <Th>Tidak Ada</Th>
            <Th>Baik</Th>
            <Th>Rusak</Th>
            <Th>Tidak Ada</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.length > 0 ? (
            data?.map((val, ind) => (
              <Tr>
                <TdFixed>{ind + 1}</TdFixed>
                <Td>{val.nama_item_checklist ?? '-'}</Td>
                <Td className="text-center">
                  {action === 'detail' ? (
                    values?.[type]?.[ind]?.status_value === 'ada' ? (
                      <IoCheckmark />
                    ) : (
                      <IoClose />
                    )
                  ) : (
                    <Form.Check
                      checked={values?.[type]?.[ind]?.status_value === 'ada'}
                      type="radio"
                      name={`${type}[${ind}].status_value`}
                      isInvalid={
                        errors?.[type]?.[ind]?.status_value &&
                        touched?.[type]?.[ind]?.status_value &&
                        true
                      }
                      feedback={
                        errors?.[type]?.[ind]?.status_value &&
                        touched?.[type]?.[ind]?.status_value &&
                        errors?.[type]?.[ind]?.status_value
                      }
                      onChange={() => {
                        setValues({
                          ...values,
                          [type]: values?.[type]?.map((res, i) =>
                            i === ind ? { ...res, status_value: 'ada' } : res
                          ),
                        });
                      }}
                    />
                  )}
                </Td>
                <Td className="text-center">
                  {action === 'detail' ? (
                    values?.[type]?.[ind]?.status_value === 'tidak ada' ? (
                      <IoCheckmark />
                    ) : (
                      <IoClose />
                    )
                  ) : (
                    <Form.Check
                      checked={
                        values?.[type]?.[ind]?.status_value === 'tidak ada'
                      }
                      type="radio"
                      name={`${type}[${ind}].status_value`}
                      isInvalid={
                        errors?.[type]?.[ind]?.status_value &&
                        touched?.[type]?.[ind]?.status_value &&
                        true
                      }
                      feedback={
                        errors?.[type]?.[ind]?.status_value &&
                        touched?.[type]?.[ind]?.status_value &&
                        errors?.[type]?.[ind]?.status_value
                      }
                      onChange={() => {
                        setValues({
                          ...values,
                          [type]: values?.[type]?.map((res, i) =>
                            i === ind
                              ? {
                                  ...res,
                                  status_value: 'tidak ada',
                                  kondisi_value: 'tidak ada',
                                }
                              : res
                          ),
                        });
                      }}
                    />
                  )}
                </Td>
                <Td className="text-center">
                  {action === 'detail' ? (
                    values?.[type]?.[ind]?.kondisi_value === 'baik' ? (
                      <IoCheckmark />
                    ) : (
                      <IoClose />
                    )
                  ) : (
                    <Form.Check
                      checked={values?.[type]?.[ind]?.kondisi_value === 'baik'}
                      type="radio"
                      disabled={
                        values?.[type]?.[ind]?.status_value === 'tidak ada'
                      }
                      name={`${type}[${ind}].kondisi_value`}
                      isInvalid={
                        errors?.[type]?.[ind]?.kondisi_value &&
                        touched?.[type]?.[ind]?.kondisi_value &&
                        true
                      }
                      feedback={
                        errors?.[type]?.[ind]?.kondisi_value &&
                        touched?.[type]?.[ind]?.kondisi_value &&
                        errors?.[type]?.[ind]?.kondisi_value
                      }
                      onChange={() => {
                        setValues({
                          ...values,
                          [type]: values?.[type]?.map((res, i) =>
                            i === ind ? { ...res, kondisi_value: 'baik' } : res
                          ),
                        });
                      }}
                    />
                  )}
                </Td>
                <Td className="text-center">
                  {action === 'detail' ? (
                    values?.[type]?.[ind]?.kondisi_value === 'rusak' ? (
                      <IoCheckmark />
                    ) : (
                      <IoClose />
                    )
                  ) : (
                    <Form.Check
                      checked={values?.[type]?.[ind]?.kondisi_value === 'rusak'}
                      disabled={
                        values?.[type]?.[ind]?.status_value === 'tidak ada'
                      }
                      type="radio"
                      name={`${type}[${ind}].kondisi_value`}
                      isInvalid={
                        errors?.[type]?.[ind]?.kondisi_value &&
                        touched?.[type]?.[ind]?.kondisi_value &&
                        true
                      }
                      feedback={
                        errors?.[type]?.[ind]?.kondisi_value &&
                        touched?.[type]?.[ind]?.kondisi_value &&
                        errors?.[type]?.[ind]?.kondisi_value
                      }
                      onChange={() => {
                        setValues({
                          ...values,
                          [type]: values?.[type]?.map((res, i) =>
                            i === ind ? { ...res, kondisi_value: 'rusak' } : res
                          ),
                        });
                      }}
                    />
                  )}
                </Td>
                <Td className="text-center">
                  {action === 'detail' ? (
                    values?.[type]?.[ind]?.kondisi_value === 'tidak ada' ? (
                      <IoCheckmark />
                    ) : (
                      <IoClose />
                    )
                  ) : (
                    <Form.Check
                      type="radio"
                      disabled={
                        values?.[type]?.[ind]?.status_value !== 'tidak ada'
                      }
                      checked={
                        values?.[type]?.[ind]?.kondisi_value === 'tidak ada'
                      }
                      name={`${type}[${ind}].kondisi_value`}
                      isInvalid={
                        errors?.[type]?.[ind]?.kondisi_value &&
                        touched?.[type]?.[ind]?.kondisi_value &&
                        true
                      }
                      feedback={
                        errors?.[type]?.[ind]?.kondisi_value &&
                        touched?.[type]?.[ind]?.kondisi_value &&
                        errors?.[type]?.[ind]?.kondisi_value
                      }
                      onChange={() => {
                        setValues({
                          ...values,
                          [type]: values?.[type]?.map((res, i) =>
                            i === ind
                              ? { ...res, kondisi_value: 'tidak ada' }
                              : res
                          ),
                        });
                      }}
                    />
                  )}
                </Td>
                <Td>
                  {action === 'detail' ? (
                    values?.[type]?.[ind]?.keterangan
                  ) : (
                    <Input
                      placeholder="Masukan keterangan"
                      name={`${type}[${ind}].keterangan`}
                      value={values?.[type]?.[ind]?.keterangan}
                      onChange={handleChange}
                    />
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
