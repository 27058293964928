export const MODAL_TITLE = {
  CREATE: 'Tambah',
  UPDATE: 'Ubah',
  DETAIL: 'Detail',
};

export const STATUS_APPROVAL = {
  PEN: 'PENDING',
  VER: 'VERIFIED',
  APP: 'APPROVED',
  REV: 'REVISE',
  REJ: 'REJECT',
};

export const KODE_MODUL = [
  'PRY',
  'ASM',
  'TNK',
  'SIA',
  'ANG',
  'CRM',
  'PRO',
  'PRD',
  'SLS',
  'COC',
  'INV',
  'MKT',
  'HRDA',
].map((kode) => ({ value: kode, label: kode }));

export const getStatusApprovalLabel = (value) => {
  switch (value) {
    case 'APP':
      return {
        variant: 'outline-success',
        label: 'APPROVED',
        level: 4,
      };
    case 'REJ':
      return {
        variant: 'outline-danger',
        label: 'REJECT',
        level: 3,
      };
    case 'REV':
      return {
        variant: 'outline-revised',
        label: 'REVISI',
        level: 2,
      };
    case 'VER':
      return {
        variant: 'outline-verified',
        label: 'VERIFIED',
        level: 4,
      };
    default:
      return {
        variant: 'outline-secondary',
        label: 'PENDING',
        level: 1,
      };
  }
};
