import { useRef, useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { ActionButton, DataStatus, InfoItemVertical } from 'components';
import { Formik } from 'formik';
import { DateConvert } from 'utilities';
import { InfoMaintenanceRequest } from './InfoMaintenanceRequest';
import { TableRincianPekerjaan } from './TableRincianPekerjaan';
import { TableSummaryBiaya } from './TableSummaryBiaya';
import { TabRincianBiaya } from './TabRincianBiaya';
import { CloseWorkOrderApi } from 'api';

export const ModalDetailWorkOrder = ({ show, data, onClose }) => {
  const formikRef = useRef();
  const [fetchData, setFetchData] = useState({
    loading: true,
    success: false,
  });
  const id_work_order = data?.id_work_order;

  const formInitialValues = (data) => ({
    ...data,
    is_mekanik_eksternal: true,
    id_petugas_maintenance: null,
    nama_petugas_eksternal: null,
    id_vendor: null,
    rincian_pekerjaan: [],
    bahan: [],
    upah: [],
    alat_mesin: [],
    subkon: [],
    overhead: [],
  });

  const mapBuaso = (list, buaso = 'bahan') => {
    return list?.map((e) => ({
      ...e,
      [`kode_item_${buaso}`]: e.kode_item ?? e.kode_item_aset,
      [`nama_item_${buaso}`]: e.nama_item ?? e.nama_item_aset,
      [`qty_work_order_${buaso}`]: e?.[`qty_work_order_${buaso}`],
      harga_satuan: e.harga_satuan,
      satuan: e.nama_satuan ?? '-',
    }));
  };

  const mapFromServer = (data) => {
    return {
      ...data,
      bahan: mapBuaso(data?.bahan, 'bahan'),
      upah: mapBuaso(data?.upah, 'upah'),
      alat_mesin: mapBuaso(data?.alat_mesin, 'alat_mesin'),
      subkon: mapBuaso(data?.subkon, 'subkon'),
      overhead: mapBuaso(data?.overhead, 'overhead'),
      catatan: data.catatan_maintenance_request,
    };
  };

  useEffect(() => {
    if (id_work_order) {
      (() => {
        setFetchData({ loading: true, success: false });

        CloseWorkOrderApi.getSingleWorkOrder({ id_work_order })
          .then((res) => {
            setFetchData({ loading: false, success: true });
            const data = res.data.data;

            formikRef.current.setValues((prev) => ({
              ...prev,
              ...mapFromServer(data),
            }));
          })
          .catch((err) => {
            setFetchData({ loading: false, success: false });
          });
      })();
    }
  }, [id_work_order]);

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialValues={formInitialValues(data)}
    >
      {({ values }) => (
        <Modal scrollable show={show} size="xl" onHide={onClose}>
          <Modal.Header closeButton>Detail Work Order</Modal.Header>
          <Modal.Body>
            {fetchData.loading || !fetchData.success ? (
              <DataStatus
                loading={fetchData.loading}
                text={
                  fetchData.loading ? 'Memuat data...' : 'Gagal memuat data!'
                }
              />
            ) : (
              <>
                <InfoMaintenanceRequest data={values} />

                <hr />

                <Row>
                  <Col>
                    <InfoItemVertical
                      label="Tgl. Work Order"
                      text={
                        values?.tgl_work_order
                          ? DateConvert(new Date(values?.tgl_work_order)).detail
                          : '-'
                      }
                    />
                  </Col>

                  <Col>
                    <InfoItemVertical
                      label="No. Work Order"
                      text={values.no_work_order}
                    />
                  </Col>
                </Row>

                <InfoItemVertical
                  label="Item Aset"
                  text={values.nama_item_aset}
                />

                <InfoItemVertical
                  label="Status Petugas Maintenance"
                  text={
                    values?.is_mekanik_eksternal
                      ? 'Mekanik Eksternal'
                      : 'Mekanik Internal'
                  }
                />

                <InfoItemVertical
                  label="Petugas Maintenance"
                  text={values.nama_petugas ?? '-'}
                />

                <InfoItemVertical
                  label="Vendor"
                  text={values.nama_vendor ?? 'AJB'}
                />

                <TableRincianPekerjaan />

                <TabRincianBiaya />

                <InfoItemVertical
                  label="Keterangan"
                  text={values.keterangan ?? '-'}
                />

                <TableSummaryBiaya />
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Row className="d-flex justify-content-end align-items-center">
              <ActionButton
                className="mr-2"
                variant="light"
                text="Kembali"
                onClick={onClose}
              />
            </Row>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
